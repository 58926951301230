import React, { useState, createRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import enLocale from '@fullcalendar/core/locales/en-gb';
import arLocale from '@fullcalendar/core/locales/ar';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Input,
  Icon,
  Placeholder,
} from 'semantic-ui-react';

import Moment from 'react-moment';

import moment from 'moment';
import { format } from 'date-fns';

import { NavLink, useHistory } from 'react-router-dom';

import Add from './add';
import Edit from './edit';
import Preview from './preview/index';
import Filters from './calendarFilter';

import { getAllWorkOrders, humanize } from '../../config/functions';

import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import DatePicker, { registerLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar-SA';
import enGB from 'date-fns/locale/en-GB/index.js';
import 'react-datepicker/dist/react-datepicker.css';
import {
  CreateIcon,
  Kanbanflag,
  ProcedurePlusIcon,
  WorkOrderIcon,
} from '../../../Assets/Icons/svg';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
registerLocale('ar', ar);

export const WorkOrdersCalendar = () => {
  const { userPermissions } = usePermissionsSimplified();
  const history = useHistory();

  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      // location.subLocations.forEach((subLocation) => {
      //   if (!allIds.some((inv) => inv == subLocation?.id)) {
      //     allIds.push(subLocation?.id);
      //   }
      // });
    });

    const currentUserId = useSelector((state) => {
      if (state?.auth?.user?.id) {
        return state.auth.user.id;
      } else {
        return null;
      }
    })

  const { DisplayFormattedText } = useDisplayFormattedText();

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [seriesEdit, setSeriesEdit] = useState(false);
  const [weekFlag, setWeekFlag] = useState(false);
  const [dayFlag, setDayFlag] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [targetedData, setTargetedData] = useState();
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const [togglePreview, setTogglePreviw] = useState(false);
  const [modalWidth, setModalWidth] = useState(Math.max(window.innerWidth / 2, 514));
  const [dueDateParam, setDueDateParam] = useState(new Date());

  const [data, setData] = useState([]);
  const [fileData, setFileData] = useState([]);

  const [initData, setInitData] = useState([]);

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const calendarRef = createRef();

  const isPastDue = (date) => {
    var date = moment(date).format('YYYY-MM-DD');
    var now = moment().format('YYYY-MM-DD');

    return now > date;
  };


  const statusOptions = [
    { value: 'open', color: '#0C66E4', bg_color: '#E9F2FF' },
    { value: 'inProgress', color: '#E2B203', bg_color: '#E2B2031A' },
    { value: 'onHold', color: '#D14040', bg_color: '#D140401A' },
    { value: 'inReview', color: '#172B4D', bg_color: '#172B4D1A' },
    { value: 'completed', color: '#4FAB68', bg_color: '#E2F2E7' },
  ];

  /**
   * The function `fetchData` fetches work orders data, filters out cancelled work orders, processes the data, and sets the necessary state variables for displaying events.
   */
  const fetchData = async () => {
    const fetchWoData = await  await getAllWorkOrders(organisationId, '', 1, 99999,[],[],[],[],[],[],[],'',[],[],role,allIds,currentUserId,true);
    const fetchData = fetchWoData.response.filter(
      (reason) => reason.attributes.status !== 'cancelled'
    );

    if (fetchData) {
      setFileData(fetchData);
      const events_g = fetchData.map((el) => {
        const eventStartDay = new Date(el.attributes.dueDate);
        const eventStartDayGetDay = eventStartDay.getDay();
        const eventStartDayGetMonth = eventStartDay.getMonth();
        const eventStartDayGetYear = eventStartDay.getFullYear();

        let eventBackground = '#3788d8';
        let eventBorder = '#3788d8';
        let statusData = statusOptions.find(
          (status) => status.value == el?.attributes?.status
        );
        if (statusData) {
          eventBackground = statusData.bg_color;
          eventBorder = statusData.color;
        }

        const startTimeString = el.attributes.dueTime
          ? el.attributes.dueTime
          : '10:00:00';

        const endTimeString = el.attributes.endTime
          ? el.attributes.endTime.substring(0, el.attributes.endTime.length - 4)
          : '17:00:00';

        let returned_array = [
          {
            id: el.id,
            data: el,
            title: el.attributes.title,

            start: el.attributes.dueDate + 'T' + '10:00:00',
            end: el.attributes.dueDate + 'T' + startTimeString,
            backgroundColor: eventBackground,
            borderColor: eventBorder,
            textColor: '#172B4D',
            display: 'block',
            description: el?.attributes?.createdByUser?.data?.attributes
              ? el?.attributes?.createdByUser?.data?.attributes?.firstName +
                ' ' +
                el?.attributes?.createdByUser?.data?.attributes?.lastName
              : '',
            priority: el.attributes.priority,
            // allDay: false,
          },
        ];

        return returned_array;
      });

      const newEvents_g = [].concat.apply([], events_g);

      setData(newEvents_g);
      setInitData(newEvents_g.map((el) => el.data));
    }
  };

  useEffect(() => {
    fetchData();

    const removePopups = () => {
      const otherDays = document.getElementsByClassName('fc-day');
      for (var i = 0; i < otherDays.length; i++) {
        if (otherDays[i].children.length > 1)
          otherDays[i].removeChild(otherDays[i].children[1]);
      }
    };
    document.body.addEventListener('click', removePopups);

    return () => {
      document.body.removeEventListener('click', removePopups);
    };
  }, [refresh]);

  /*
   * The function `handleSetDataByFilter` processes new orders data to create events with specific properties and sets the processed data to be displayed.
   */
  const handleSetDataByFilter = (newOrders) => {
    const events_g = newOrders.map((el) => {
      const eventStartDay = new Date(el.attributes.dueDate);
      const eventStartDayGetDay = eventStartDay.getDay();
      const eventStartDayGetMonth = eventStartDay.getMonth();
      const eventStartDayGetYear = eventStartDay.getFullYear();

      let eventBackground = '#3788d8';
      let eventBorder = '#3788d8';
      let statusData = statusOptions.find(
        (status) => status.value == el?.attributes?.status
      );
      if (statusData) {
        eventBackground = statusData.bg_color;
        eventBorder = statusData.color;
      }

      const startTimeString = el.attributes.dueTime
        ? el.attributes.dueTime
        : '10:00:00';

      const endTimeString = el.attributes.endTime
        ? el.attributes.endTime.substring(0, el.attributes.endTime.length - 4)
        : '17:00:00';

      let returned_array = {
        id: el.id,
        data: el,
        title: el.attributes.title,
        start: el.attributes.dueDate + 'T' + '10:00:00',
        end: el.attributes.dueDate + 'T' + startTimeString,
        backgroundColor: eventBackground,
        borderColor: eventBorder,
        textColor: '#172B4D',
        display: 'block',
        description: el?.attributes?.createdByUser?.data?.attributes
          ? el?.attributes?.createdByUser?.data?.attributes?.firstName +
            ' ' +
            el?.attributes?.createdByUser?.data?.attributes?.lastName
          : '',
        priority: el.attributes.priority,
        // allDay: false,
      };

      return returned_array;
    });
    setData(events_g);
  };

  const handleDateSelect = (selectedDate) => {
    setCurrentDate(selectedDate);
  };

  return (
    <>
        <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {' '}
            <WorkOrderIcon />
            {t.workOrders.title}
          </Header>
          <div></div>

          {userPermissions?.['work-order']?.create ? (
            <button
            onClick={() => {
              setToggleAsideModal(true);
            }}
            className={`add-item-btn ${
              selectedLanguage == "en" ? "mr-7" : "ml-7"
            }`}
          >
            <span>
              <CreateIcon />
              {t.workOrders.create_WO}
            </span>
          </button>
          ) : null}
        </div>
   
          <div className="new-listing-filter wo-filter sticky-filter mt-8">
            <Filters
          data={initData}
          currentUserId={currentUserId}
          setWeekFlag={setWeekFlag}
          weekFlag={weekFlag}
          setDayFlag={setDayFlag}
          dayFlag={dayFlag}
          setDataByFilter={handleSetDataByFilter}
          hasStatus={false}
          calendarRef={calendarRef}
          fileData={fileData}
          roleData={role}
          allIds={allIds}
        />
          </div>
      
      </div>
    <div className="new-listing-container">

      <div className="new-listing-filter">
       

        {/* {console.log(data, initData)} */}
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          locales={[enLocale, arLocale]}
          locale={selectedLanguage}
          direction={selectedLanguage == 'ar' ? 'rtl' : 'ltr'}
          headerToolbar={false}
          initialView="dayGridMonth"
          eventOverlap={false}
          editable={false}
          selectable={true}
          dayMaxEvents={2}
          selectMirror={true}
          eventContent={({ event }) => {
            let viewType = calendarRef.current.getApi().view.type;

            const commonContent =
              // viewType !== 'timeGridDay'
              //   ?
              `<div class='${
                viewType == 'timeGridWeek'
                  ? 'day-container '
                  : viewType == 'timeGridDay'
                  ? 'day-container '
                  : ''
              }${event?.extendedProps?.data?.attributes?.status} d-flex ${
                viewType !== 'timeGridDay' ? 'justify-content-between' : ''
              }'><div class='overflow-hidden event-container-new ${
                viewType !== 'timeGridDay' ? '' : 'd-flex'
              }'>
            <p title='${event.title}'>${DisplayFormattedText(
                event.title,
                8,
                true
              )}</p>
            <p class=${viewType !== 'timeGridDay' ? '' : 'mt-1'} title='by ${
                event?.extendedProps?.description &&
                ['dayGridMonth', 'timeGridDay'].includes(viewType)
                  ? event?.extendedProps?.description
                  : ''
              }'>${
                event?.extendedProps?.description &&
                ['dayGridMonth', 'timeGridDay'].includes(viewType)
                  ? `by
                  ${
                    viewType !== 'timeGridDay'
                      ? DisplayFormattedText(
                          event?.extendedProps?.description,
                          8,
                          true
                        )
                      : event?.extendedProps?.description
                  }
                  
                 `
                  : ''
              }</p>
            </div>
            <div  class="icon-container ${
              viewType === 'dayGridMonth' ? '' : 'd-flex flex-row mb-1'
            }">
            ${
              event.extendedProps.data?.attributes?.orderType === 'scheduled' &&
              ['dayGridMonth', 'timeGridDay'].includes(viewType)
                ? ` <div class="schedual-icon"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M4.5 9.96387C4.5 8.72637 5.0025 7.60137 5.82 6.78387L4.755 5.71887C3.675 6.80637 3 8.30637 3 9.96387C3 13.0239 5.2875 15.5439 8.25 15.9114V14.3964C6.1275 14.0364 4.5 12.1914 4.5 9.96387ZM15 9.96387C15 6.64887 12.315 3.96387 9 3.96387C8.955 3.96387 8.91 3.97137 8.865 3.97137L9.6825 3.15387L8.625 2.08887L6 4.71387L8.625 7.33887L9.6825 6.28137L8.8725 5.47137C8.9175 5.47137 8.9625 5.46387 9 5.46387C11.4825 5.46387 13.5 7.48137 13.5 9.96387C13.5 12.1914 11.8725 14.0364 9.75 14.3964V15.9114C12.7125 15.5439 15 13.0239 15 9.96387Z" fill="#44546F"/>
            </svg></div>`
                : ''
            }
          
            ${
              ['dayGridMonth', 'timeGridDay'].includes(viewType)
                ? `  <span class='pill kanban ${event?.extendedProps?.priority}'><svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.86 5.5L12.26 7.5H17.5V13.5H14.14L13.74 11.5H6.5V5.5H11.86ZM13.5 3.5H4.5V20.5H6.5V13.5H12.1L12.5 15.5H19.5V5.5H13.9L13.5 3.5Z"
        fill='#fff'
      />
    </svg>`
                : ''
            }
            </span></div></div>`;
            //         : `<div class='day-container d-flex ${
            //             event?.extendedProps?.data.attributes.status
            //           }'><div class='event-container-new d-flex'>
            //         <p title='${event.title}'> ${DisplayFormattedText(
            //           event.title,
            //           12,
            //           true
            //         )}</p>
            //         <p class='mt-1' title='by ${
            //           event?.extendedProps?.description &&
            //           ['dayGridMonth', 'timeGridDay'].includes(viewType)
            //             ? event?.extendedProps?.description
            //             : ''
            //         }'>${
            //             event?.extendedProps?.description &&
            //             ['dayGridMonth', 'timeGridDay'].includes(viewType)
            //               ? `by ${event?.extendedProps?.description}`
            //               : ''
            //           }</p>
            //         </div>
            //         <div class="icon-container d-flex flex-row mb-1">
            //         ${
            //           event.extendedProps.data.attributes.orderType === 'scheduled'
            //             ? ` <div class="schedual-icon"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            //         <path d="M4.5 9.96387C4.5 8.72637 5.0025 7.60137 5.82 6.78387L4.755 5.71887C3.675 6.80637 3 8.30637 3 9.96387C3 13.0239 5.2875 15.5439 8.25 15.9114V14.3964C6.1275 14.0364 4.5 12.1914 4.5 9.96387ZM15 9.96387C15 6.64887 12.315 3.96387 9 3.96387C8.955 3.96387 8.91 3.97137 8.865 3.97137L9.6825 3.15387L8.625 2.08887L6 4.71387L8.625 7.33887L9.6825 6.28137L8.8725 5.47137C8.9175 5.47137 8.9625 5.46387 9 5.46387C11.4825 5.46387 13.5 7.48137 13.5 9.96387C13.5 12.1914 11.8725 14.0364 9.75 14.3964V15.9114C12.7125 15.5439 15 13.0239 15 9.96387Z" fill="#44546F"/>
            //         </svg></div>`
            //             : ''
            //         }
            //         <span class='pill kanban ${event?.extendedProps?.priority}'>
            //         <svg
            //   xmlns="http://www.w3.org/2000/svg"
            //   width="13"
            //   height="13"
            //   viewBox="0 0 24 24"
            //   fill="none"
            // >
            //   <path
            //     d="M11.86 5.5L12.26 7.5H17.5V13.5H14.14L13.74 11.5H6.5V5.5H11.86ZM13.5 3.5H4.5V20.5H6.5V13.5H12.1L12.5 15.5H19.5V5.5H13.9L13.5 3.5Z"
            //     fill='#fff'
            //   />
            // </svg>
            //         </span></div></div>`;
            return {
              html: commonContent,
            };
          }}
          eventTimeFormat={{
            hour: 'numeric',

            meridiem: true,
          }}
          events={data}
          dateClick={(info) => {
            if (!userPermissions['work-order']?.create) {
              return;
            }
            const otherDays = document.getElementsByClassName('fc-day');
            for (var i = 0; i < otherDays.length; i++) {
              if (otherDays[i].children.length > 1)
                otherDays[i].removeChild(otherDays[i].children[1]);
            }

            let this_date = info.dateStr;
            const this_date_param = new Date(this_date)
              .toISOString()
              .substring(0, 10);

            this_date = new Date(this_date);

            const this_month = this_date.toLocaleString('default', {
              month: 'long',
            });
            const this_day = this_date.getUTCDate();

            this_date = this_month + ' ' + this_day;
            let popupList = document.createElement('div'); // Create a <p> node
            popupList.classList.add(
              'ui',
              'right',
              'center',
              'popup',
              'transition',
              'visible'
            );

            popupList.style.top = '10px';

            let popupHeader = document.createElement('h5');
            popupHeader.classList.add('ui', 'header');
            popupHeader.style.borderBottom = '1px solid rgba(34,36,38,.15)';
            popupHeader.style.paddingBottom = '7px';
            popupHeader.style.marginBottom = '7px';
            popupHeader.innerHTML = t.common.add_to + this_date;
            popupList.appendChild(popupHeader);

            let popupUL = document.createElement('div');
            popupUL.classList.add('ui', 'list');
            popupUL.setAttribute('role', 'list');

            // popuplist li 1
            let popupLI1 = document.createElement('div');
            popupLI1.classList.add('item');
            popupLI1.setAttribute('role', 'listitem');
            popupLI1.style.fontSize = '14px';
            popupLI1.style.marginBottom = '5px';
            popupLI1.style.cursor = 'pointer';

            let popupLI1Icon = document.createElement('i');
            popupLI1Icon.classList.add('tasks', 'icon');
            popupLI1Icon.setAttribute('aria-hidden', true);
            popupLI1Icon.style.color = 'black';

            let popupLI1Content = document.createElement('div');
            popupLI1Content.classList.add('content');
            popupLI1Content.innerHTML = t.common.newOrder;

            popupLI1.appendChild(popupLI1Icon);
            popupLI1.appendChild(popupLI1Content);

            popupLI1.onclick = () => {
              setDueDateParam(this_date_param);
              setToggleAsideModal(true);
            };

            // popuplist li 2
            // let popupLI2 = document.createElement("div");
            // popupLI2.classList.add("item");
            // popupLI2.setAttribute("role", "listitem");
            // popupLI2.style.fontSize = "14px";
            // popupLI2.style.marginBottom = "5px";
            // popupLI2.style.cursor = "pointer";

            // let popupLI2Icon = document.createElement("i");
            // popupLI2Icon.classList.add("find", "icon");
            // popupLI2Icon.setAttribute("aria-hidden", true);
            // popupLI2Icon.style.color = "black";

            // let popupLI2Content = document.createElement("div");
            // popupLI2Content.classList.add("content");
            // popupLI2Content.innerHTML = "New Inspection";

            // popupLI2.appendChild(popupLI2Icon);
            // popupLI2.appendChild(popupLI2Content);

            // popupLI2.onclick = () => {
            //   setDueDateParam({
            //     orderType: "inspection",
            //     startDay: this_date_param,
            //   });

            //   setToggleAsideModal(true);
            // };

            // // popuplist li 3
            // let popupLI3 = document.createElement("div");
            // popupLI3.classList.add("item");
            // popupLI3.setAttribute("role", "listitem");
            // popupLI3.style.fontSize = "14px";
            // popupLI3.style.cursor = "pointer";

            // let popupLI3Icon = document.createElement("i");
            // popupLI3Icon.classList.add("calendar", "icon", "outline", "plus");
            // popupLI3Icon.setAttribute("aria-hidden", true);
            // popupLI3Icon.style.color = "black";

            // let popupLI3Content = document.createElement("div");
            // popupLI3Content.classList.add("content");
            // popupLI3Content.innerHTML = "New Custom Task";

            // popupLI3.appendChild(popupLI3Icon);
            // popupLI3.appendChild(popupLI3Content);

            // popupLI3.onclick = () => {
            //   setDueDateParam({
            //     orderType: "custom",
            //     startDay: this_date_param,
            //   });

            //   setToggleAsideModal(true);
            // };

            popupUL.appendChild(popupLI1);
            // popupUL.appendChild(popupLI2);
            // popupUL.appendChild(popupLI3);

            popupList.appendChild(popupUL);

            popupList.onclick = (e) => {
              e.stopPropagation();
            };

            if (!info.dayEl.getElementsByClassName('popup').length > 0) {
              info.dayEl.style.position = 'relative';
              info.dayEl.appendChild(popupList);
            }
          }}
          displayEventTime={false}
          eventClick={(info) => {
            setTargetedData(info.event.extendedProps.data);
            setTogglePreviw(true);
          }}
        />
      </div>

      {/* Add WO modal */}
      <Add
       roleData={role}
       currentUserId={currentUserId}
       allIds={allIds}
        toggled={toggleAsideModal}
        dueDateParam={dueDateParam}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        untoggle={() => {
          setToggleAsideModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      {/* Edit WO modal */}

      <Edit
      roleData={role}
      allIds={allIds}
      currentUserId={currentUserId}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        toggled={toggleEditModal}
        setSeriesEdit={setSeriesEdit}
        seriesEdit={seriesEdit}
        untoggle={() => {
          setToggleEditModal(false);
          setTargetedData();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          history.push('/work-orders-calendar');
        }}
        setRefresh={setRefresh}
        refresh={refresh}
        targetedData={targetedData}
      />

      {/* View WO modal */}
      <Preview
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        targetedData={targetedData}
        setSeriesEdit={setSeriesEdit}
        seriesEdit={seriesEdit}
        edit={() => {
          
          setTogglePreviw(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setToggleEditModal(true);
        }}
        toggled={togglePreview}
        untoggle={() => {
          setTargetedData();
          setTogglePreviw(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          history.push('/work-orders-calendar');
        }}
        negative={isPastDue(
          targetedData?.attributes.dueDate +
            'T' +
            targetedData?.attributes.dueTime
        )}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div></>
  );
};
