import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import {
  AddIconDashboard,
  CategoryElectrical,
  CategoryInspection,
  CategoryMechanical,
  CategoryRefrigeration,
  CategorySafety,
  CategorySafety1,
  CatgoryPreventive,
  CorrectiveIcon,
  Damage,
  DashboardWOStatusIcon,
  FullArrow,
  OperationProcedure,
  PlannedIcon,
  Project,
  UnPlannedIcon,
} from "../../../../Assets/Icons/svg";

const TopWorkOrders = ({
  data12,
  dashBoardData,
  setModalData,
  setOpenModal,
  openModal,
}) => {
  const t = useSelector(selectTranslations);
  const [showAssign, setShowAssign] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [technicianData, setTechnicianData] = useState([]);
  useEffect(() => {
    if (Object.values(dashBoardData?.topTenWorkOrders)?.length > 0) {
    }
  }, [dashBoardData]);

  let categoryIcon = {
    damage: {
      key: 0,
      text: t.workOrders.form.category_options[0],
      icon: <Damage width="10px" height="10px" />,
    },
    "corrective action Downtime": {
      key: 2,
      value: "corrective action Downtime",
      text: t.workOrders.form.category_options[1],
      icon: <CorrectiveIcon width="10px" height="10px" />,
    },

    "planned Downtime": {
      key: 4,
      value: "planned Downtime",
      text: t.workOrders.form.category_options[2],
      icon: <PlannedIcon width="10px" height="10px" />,
    },

    "unplanned Downtime": {
      key: 10,
      value: "unplanned Downtime",
      text: t.workOrders.form.category_options[3],
      icon: <UnPlannedIcon width="10px" height="10px" />,
    },
    inspection: {
      key: 11,
      value: "inspection",
      text: t.workOrders.form.category_options[4],
      icon: <CategoryInspection width="10px" height="10px" />,
    },

    electrical: {
      key: 1,
      value: "electrical",
      text: t.workOrders.form.category_options[5],
      icon: <CategoryElectrical width="10px" height="10px" />,
    },

    mechanical: {
      key: 3,
      value: "mechanical",
      text: t.workOrders.form.category_options[6],
      icon: <CategoryMechanical width="10px" height="10px" />,
    },

    HVAC: {
      key: 6,
      value: "HVAC",
      text: t.workOrders.work_order_category.HVAC,
      icon: <CategoryRefrigeration width="10px" height="10px" />,
    },

    project: {
      key: 17,
      value: "project",
      text: t.workOrders.form.category_options[9],
      icon: <Project width="10px" height="10px" />,
    },
    safety: {
      key: 7,
      value: "safety",
      text: t.workOrders.form.category_options[10],
      icon: <CategorySafety1 width="12px" height="12px"/>,
    },
    sop: {
      key: 27,
      value: "sop",
      text: t.workOrders.form.category_options[11],
      icon: <OperationProcedure width="10px" height="10px" />,
    },
    "preventive maintenance": {
      key: 16,
      value: "preventive maintenance",
      text: t.workOrders.form.category_options[8],
      icon: <CatgoryPreventive width="10px" height="10px" />,
    },
  };

  return (
    <>
      {Object.values(dashBoardData?.topTenWorkOrders)?.length > 0 ? (
        <>
          <Grid.Column className=" completion-column pl-0 pt-0">
            <div
              className={
                openModal
                  ? "completion-column-data no-border"
                  : "completion-column-data"
              }
            >
              <div className="completion-cotainer d-flex justify-content-between mt-2">
                <div className="completion-title d-flex">
                  <span className="title">{t.dashboard.cards.top10Wo}</span>
                  <p
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      marginTop: "-10px",
                    }}
                    onMouseOver={() => setShowAssign(true)}
                    onMouseLeave={() => setShowAssign(false)}
                    className={
                      selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                    }
                  >
                    <Tooltip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={selectedLanguage == "en" ? "right" : "left"}
                      show={showAssign}
                      textBoxWidth="105px"
                      fontSize="10px"
                      fontWeight="400"
                      fontFamily={
                        selectedLanguage == 'ar'
                          ? 'Noto Kufi Arabic'
                          : 'Roboto'
                      }
                      padding="0px 0px 0px 8px"
                      borderRadius="5px"
                      static
                      arrowAlign="end"
                      moveRight="8px"
                      // moveDown="10px"
                    >
                      <span
                        style={{ padding: "0px 10px" }}
                        className="work-order-tooltip"
                      >
                        {t.dashboard.tooltip.top10}
                      </span>
                    </Tooltip>
                    <DashboardWOStatusIcon />
                  </p>
                </div>

                <span
                  style={{ marginTop: "-10px", gap: "10px" }}
                  className="d-flex"
                >
                  <span
                    onClick={() => {
                      setOpenModal(true);
                      setModalData("Top10");
                      setShowExpandTooltip(false);
                    }}
                    style={{ marginTop: "2px" }}
                    className=""
                  >
                    {openModal ? null : (
                      <p
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          marginTop: "-10px",
                        }}
                        onMouseOver={() => setShowExpandTooltip(true)}
                        onMouseLeave={() => setShowExpandTooltip(false)}
                        className=" mt-1"
                      >
                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="top"
                          show={showExpandTooltip}
                          textBoxWidth="80px"
                          fontSize="10px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == 'ar'
                              ? 'Noto Kufi Arabic'
                              : 'Roboto'
                          }
                          padding="8px 8px 8px 8px"
                          borderRadius="5px"
                          static
                          arrowAlign="start"
                          moveRight="5px"
                          moveDown="3px"
                        >
                          <span
                            style={{ padding: "0px 10px" }}
                            className="work-order-tooltip"
                          >
                            {t.dashboard.expand_full}
                          </span>
                        </Tooltip>
                        <FullArrow />
                      </p>
                    )}
                  </span>
                  <p
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      marginTop: "-10px",
                    }}
                    onMouseOver={() => setShowAddCustomizedTooltip(true)}
                    onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                    className=" mt-1"
                  >
                    <Tooltip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position="top"
                      show={showAddCustomizedTooltip}
                      textBoxWidth="105px"
                      fontSize="10px"
                      fontWeight="400"
                      fontFamily={
                        selectedLanguage == 'ar'
                          ? 'Noto Kufi Arabic'
                          : 'Roboto'
                      }
                      padding="8px 8px 8px 8px"
                      borderRadius="5px"
                      static
                      arrowAlign="end"
                      moveRight="0px"
                    >
                      <span
                        style={{ padding: "0px 10px" }}
                        className="work-order-tooltip"
                      >
                        {t.dashboard.add_customized}
                      </span>
                    </Tooltip>
                    {/* <AddIconDashboard /> */}
                  </p>
                </span>
              </div>

              <div className="completion-status-part">
                <Grid className="wo-data-container" columns={1    }>
                  {/* <Grid.Column className="wo-data-chart d-flex align-items-center text-center">
      <div className="w-100">
     
        <Chart
          options={chartOptions}
          series={[70, 90, 50, 10]}
          type="donut"
          height={350}
        />
      </div>
    </Grid.Column> */}

                  <Grid.Column className="wo-data-chart pr-0 d-flex align-items-center text-center">
                    <div className="resolution-time-table w-100">
                      <div
                      style={{paddingLeft:"17px"}}
                        className={
                          openModal
                            ? "table-container top-ten h-500"
                            : "table-container top-ten"
                        }
                      >
                        <table style={{ width: "-webkit-fill-available" }}>
                          <thead>
                            <tr>
                              <th style={{width:"385px"}} rowspan="2">{t.dashboard.cards.category}</th>
                              <th style={{width:"193px"}} rowspan="2">{t.dashboard.cards.total_wos}</th>
                              <th style={{width:"193px"}} rowspan="2">{t.workRequests.status.pending}</th>
                              <th style={{width:"182px"}} className="">
                                {t.dashboard.cards.comp}
                              </th>{" "}
                              <th style={{width:"154px"}} className="">
                                {t.dashboard.cards.overdueWO}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                          {Object.entries(dashBoardData?.topTenWorkOrders) // Take the first 5 entries
                    .map(([key, value], index) => (
                      <tr key={index}>
                        <td
                          style={{
                            minWidth: "150px",
                            color: "#172B4D",
                          }}
                        >
                          {categoryIcon[key]?.icon}{" "}
                          {categoryIcon[key]?.text}
                        </td>
                        <td ><div>{value?.totalWOs}</div></td>
                        <td ><div>{value?.pending}</div></td>
                        <td><div>{value?.completed}</div></td>
                        <td><div>{value?.overdue}</div></td>
                      </tr>
                    ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div className="status-container">
        {statusOptions.map((status, index) => (
          <React.Fragment key={index}>
            <div
              style={{ gap: '16px' }}
              className="item-value mb-3 d-flex align-items-center justify-content-end"
            >
              <div className="label-text">
                {status.label}
                <p className=" text-right">15</p>
              </div>
              <div className="">
                <Label
                  circular
                  empty
                  color={status.color}
                  style={{
                    marginRight: '5px',
                    width: '12px',
                    height: '12px',
                  }}
                  size="medium"
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div> */}
                  </Grid.Column>

         
                </Grid>

              </div>
            </div>
          </Grid.Column>

          {/* <Grid.Column className="completion-column pl-0 pt-0"></Grid.Column> */}
        </>
      ) : null}
    </>
  );
};

export default TopWorkOrders;
