import { filter } from 'lodash';
import React, { useEffect, useState, createRef } from 'react';
import _ from 'lodash';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
} from 'semantic-ui-react';
import location_icon from "../../../Assets/Icons/loc_option_parent.png";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import LocationSearchDropdown from '../../components/WorkOrder/LocationSearchDropdown';
import ToolTip from "react-power-tooltip";
import {
  getAllLocations,
  getUsersByRole,
  getAllTeams,
  humanize,
  toggle,
  IMAGES_URL,
  getAllCustomers,
  getAllParentLocations,
  getActiveUsersByRole,
} from '../../config/functions';
import { selectTranslations } from '../../config/i18n/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  DownloadIcon,
  ActionBtnIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  WhiteDownChevron,
} from '../../../Assets/Icons/svg';
import WorkOrderSearchDropdown from '../../components/WorkOrder/WorkOrderSearchDropdown';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import moment from 'moment';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import {
  fetchAllTeamsData,
  fetchTeamsWithFilter,
} from '../../../redux/reducers/teamsReducer';
import { toast } from 'react-toastify';

const Filters = ({
  data,
  searchTitle,
  setDataByFilter,
  hasStatus,
  fileData,
  filterOptions = [],
  setFilterOptions,
  setSearchTitle,
  debounceValue,
  setloader,
  sortData,
  currentUserId,userRole
}) => {
  const t = useSelector(selectTranslations);
  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });


  // const currentUserId = useSelector((state) => {
  //   if (state?.auth?.user?.id) {
  //     return state.auth.user.id;
  //   } else {
  //     return null;
  //   }
  // });
  const [showTooltip, setShowTooltip] = useState(false);

  const [filtersTrigger, setFiltersTrigger] = useState({
    requestedByType: 'supervisors',
    requestedBy: {
      supervisors: {
        initOptions: [],
        options: [],
      },
      customers: {
        initOptions: [],
        options: [],
      },
    },
    locations: {
      initOptions: [],
      options: [],
    },
    superior: {
      initOptions: [],
      options: [],
    },
    customers: {
      initOptions: [],
      options: [],
    },
    teams: {
      initOptions: [],
      options: [],
    },
    dueDate: {
      options: [
        { key: 0, text: 'Any Date', value: 'any_day' },
        { key: 1, text: 'Today', value: 'today' },
        { key: 2, text: 'Tomorrow', value: 'tomorrow' },
        { key: 3, text: 'Next 7 Days', value: 'next_7_days' },
        { key: 4, text: 'Next 30 Days', value: 'next_30_days' },
        { key: 5, text: 'This Month', value: 'this_month' },
        { key: 6, text: 'This Quarter', value: 'this_quarter' },
        { key: 7, text: 'This Year', value: 'this_year' },
        { key: 8, text: 'Yesterday', value: 'yesterday' },
        { key: 9, text: 'Last Week', value: 'last_week' },
        { key: 10, text: 'Last Month', value: 'last_month' },
        { key: 11, text: 'Last Quarter', value: 'last_quarter' },
        { key: 12, text: 'Last Year', value: 'last_year' },
        { key: 13, text: 'Past Due', value: 'past_due' },
        { key: 14, text: 'Unscheduled', value: 'unscheduled' },
      ],
    },
    priority: {
      options: [
        { key: 0, text: 'None', value: 'none', color: null },
        { key: 1, text: 'Low', value: 'low', color: 'blue' },
        { key: 2, text: 'Medium', value: 'medium', color: 'orange' },
        { key: 3, text: 'High', value: 'high', color: 'red' },
      ],
    },

    status: {
      options: [
        { key: 1, text: 'Declined', value: 'declined', color: 'red' },
        { key: 2, text: 'Approved', value: 'approved', color: 'green' },
      ],
    },
  });

  const { allTeamsData } = useSelector((state) => ({
    allTeamsData: state.teams.allTeamsData,
  }));

  const [filterSearch, setFilterSearch] = useState({
    teams: '',
    superior: '',
    customer: '',
    location: '',
    requestedBy: '',
  });

  const dispatch = useDispatch();

  const [newData, setNewData] = useState(data);

  let _newData = newData;
  useEffect(() => {
    (async function() {
      setloader(true);

      await dispatch(
        fetchTeamsWithFilter({
          organisationId,
          searchName: debounceValue,
          page: '1',
          pageSize: '25',
          customer: filterOptions.customers,
          superior: filterOptions.superior,
          team: filterOptions.teams,
          location: filterOptions.locations,
          sort: sortData,
         userRole, currentUserId 
        })
      );

      setloader(false);
    })();

    // if (searchTitle.length > 0) {
    //   const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
    //   const isMatch = (result) => re.test(result.attributes.name);

    //   _newData =
    //     searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    // }

    // if (filterOptions.superior.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.superior.includes(
    //       `${el.attributes?.superior?.data?.id}`
    //     );
    //   });

    // if (filterOptions.teams.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.teams.includes(`${el?.id}`);
    //   });

    // if (filterOptions.customers.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return el?.attributes?.customers?.data?.some((item) =>
    //       filterOptions.customers.includes(`${item.id}`)
    //     );
    //   });

    // if (filterOptions.locations.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return el?.attributes?.locations?.data?.some((item) =>
    //       filterOptions.locations.includes(`${item.id}`)
    //     );
    //   });

    // if (_newData.length > 0) {
    //   setDataByFilter(_newData);
    // } else {
    //   setDataByFilter([]);
    // }
  }, [filterOptions, debounceValue]);

  useEffect(() => {
    (async function() {
      // const fetchData = await getAllCustomers(organisationId);
      let the_options_teams = [];
      let the_supervisor = [];
      // let the_options_jobTitle = [];
      let the_options_customer = [];
      let the_options_location = [];

      const fetchData = await getActiveUsersByRole(
        ['admin', 'limited_admin'],
        organisationId,!(userRole === "super_admin" || userRole === "admin") ? currentUserId : [],true
      );
      // console.log();
      if (fetchData?.data) {
        fetchData.data.map((el) => {
          the_supervisor.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.firstName + ' ' + el.lastName,
            image: {
              avatar: true,
              src: el?.profileImage?.url
                ? IMAGES_URL + el?.profileImage?.url
                : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      // const fetchTeamsData = await getAllTeams(organisationId);

      // console.log();
      // if (fetchTeamsData.response) {
      //   fetchTeamsData.response.map((el) => {
      //     the_options_teams.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: LetteredAvatarImageUrl(el.attributes.name),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      const fetchCustomerData = await getAllCustomers(organisationId,'',1,99999,!(userRole === "super_admin" || userRole === "admin")? currentUserId : []);
      if (fetchCustomerData?.response) {
        fetchCustomerData.response.forEach((el) => {
          the_options_customer.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.logo.data
                ? IMAGES_URL + el.attributes.logo.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + ' '),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      const fetchDataLocation = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      );

      let parentOptions = [];
      let subOptions = [];

      if (fetchDataLocation?.response?.length > 0) {
        fetchDataLocation.response.forEach((el) => {
          if (!(userRole === "super_admin" || userRole === "admin")) {
            // Check if the element's ID is included in allIds
            if (currentUserId?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (currentUserId?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => currentUserId?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  text: el.attributes.name,
                  image: { src: location_icon },
                  className: "main-location-dropdown-option",
                  disabled: true, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (currentUserId?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }
          } else {
            // Add parent option
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              className: "main-location-dropdown-option",
              image: { src: location_icon },
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                subOptions.push({
                  key: subEl.id,
                  value: subEl.id,
                  text: subEl.attributes.name,
                  image: { src: sub_location_icon },
                  className: "sub-location-dropdown-option",
                  parentId: el.id, // Keep track of the parent
                });
              });
            }
          }
        });

        // Sort sub-options alphabetically
        subOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted sub-options
        parentOptions.forEach((parentOption) => {
          the_options_location.push(parentOption);
          // Add sorted sub-options belonging to this parent
          subOptions.forEach((subOption) => {
            if (subOption.parentId === parentOption.key) {
              the_options_location.push(subOption);
            }
          });
        });

        // Update the options state
        // setOptions((prev) => ({
        //   ...prev,
        //   locations: the_options,
        // }));

        // return the_options;
      }

      // const fetchJobTitleData = await getAllJobTitles(organisationId);

      // if (fetchJobTitleData) {
      //   fetchJobTitleData.map((el) => {
      //     the_options_jobTitle.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.title,
      //     });
      //   });
      // }

      setFiltersTrigger({
        ...filtersTrigger,
        superior: {
          ...filtersTrigger.superior,
          initOptions: the_supervisor,
          options: the_supervisor,
        },
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options_teams,
          options: the_options_teams,
        },
        customers: {
          ...filtersTrigger.customers,
          initOptions: the_options_customer,
          options: the_options_customer,
        },
        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options_location,
          options: the_options_location,
        },
      });

      // if (fetchData) {
      //   fetchData.forEach((el) => {
      //     the_options_customer.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: el.attributes.logo.data
      //           ? IMAGES_URL +
      //             el.attributes.logo.data.attributes.formats.thumbnail.url
      //           : LetteredAvatarImageUrl(el.attributes.name + ' '),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      // const fetchTeamsData = await getAllTeams(organisationId);

      // if (fetchTeamsData) {
      //   fetchTeamsData.map((el) => {
      //     the_options_teams.push({
      //       key: el.id,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: LetteredAvatarImageUrl(`${el.attributes.name}`),
      //         style: { width: '16px', height: '16px' },
      //       },
      //     });
      //   });
      // }

      // getAllParentLocations,
      // const fetchDataLocation = await getAllParentLocations(organisationId);

      // if (fetchDataLocation) {
      //   fetchDataLocation.forEach((el) => {
      //     the_options_location.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: `${el.attributes.name}`,
      //     });
      //   });
      // }

      // const fetchDataPeople = await getUsersByRole(
      //   [
      //     'user',
      //     'supervisor',
      //     'view_only',
      //     'admin',
      //     'requester',
      //     'super_admin',
      //   ],
      //   organisationId
      // );

      // if (fetchDataPeople) {
      //   fetchDataPeople.map((el) => {
      //     the_options_user.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.firstName + ' ' + el.lastName,
      //       image: {
      //         avatar: true,
      //         src: el?.profileImage?.url
      //           ? IMAGES_URL + el?.profileImage?.url
      //           : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }
    })();
  }, []);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.superior), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      superior: {
        ...filtersTrigger.superior,
        options:
          filterSearch.superior.length > 0
            ? _.filter(filtersTrigger.superior.initOptions, isMatch)
            : filtersTrigger.superior.initOptions,
      },
    });
  }, [filterSearch.superior]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), 'i');
    const isMatch = (result) => {
      re.test(result.text);
    };

    let data = filtersTrigger.teams.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        ...filtersTrigger.teams,
        options:
          filterSearch.teams.length > 0
            ? data
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.customer), 'i');
    // console.log(re, 'res');
    const isMatch = (result) => {
      let ss = re.test(result.text);
      // console.log(ss, ' i m ss');
    };

    // console.log(
    //   _.filter(filtersTrigger.customers.initOptions, isMatch),
    //   ' i m csds'
    // );

    let data = filtersTrigger.customers.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      customers: {
        ...filtersTrigger.customers,
        options:
          filterSearch.customer.length > 0
            ? data
            : filtersTrigger.customers.initOptions,
      },
    });
  }, [filterSearch.customer]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), 'i');
    // console.log(re, 'res');
    const isMatch = (result) => {
      let ss = re.test(result.text);
      // console.log(ss, ' i m ss');
    };

    let data = filtersTrigger.locations.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        ...filtersTrigger.locations,
        options:
          filterSearch.location.length > 0
            ? data
            : filtersTrigger.locations.initOptions,
      },
    });
  }, [filterSearch.location]);

  const handleSearchAssigneesChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      customer: e.target.value,
    });
  };
  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };

  const handleSearchTeamsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      teams: e.target.value,
    });
  };

  const handleSearchSupervisorChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      superior: e.target.value,
    });
  };

  fileData = allTeamsData;

  const headers = [
    { label: t.assets.table.ID, key: 'id' },
    { label: t.people.table.name, key: 'name' },
    // { label: t.assets.form.description, key: 'description' },
    { label: t.teams.team_info_form.superior, key: 'superior' },
    { label: t.people.work_info_form.locations, key: 'location' },
    { label: t.teams.team_info_form.customers, key: 'customer' },
    { label: t.teams.team_info_form.team_members, key: 'team_members' },
    // { label: t.common.created_by, key: 'createdBy' },
  ];

  const downloadXLS = async () => {
    const hasValues = Object.values(filterOptions).some(
      (option) => option.length > 0
    );

    if (!hasValues && searchTitle == '') {
      let mydata = await dispatch(fetchAllTeamsData({ organisationId, userRole, currentUserId }));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let teams_data = [];

      teams_data.id = fileData[i].id;
      teams_data.name = fileData[i].attributes.name || t.common.na;

      teams_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description.replace(/\s+/g, ' ').trim()
        : t.common.na;
      teams_data.superior = fileData[i]?.attributes?.superior?.data
        ? fileData[i]?.attributes.superior?.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes.superior?.data?.attributes?.lastName
        : t.common.na;

      let locationsData = fileData[i].attributes.locations?.data;
      let locationsString = '';

      if (locationsData?.length > 0) {
        locationsString = locationsData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        locationsString = t.common.na;
      }

      teams_data.locations = locationsString;

      let assigneePeople = fileData[i].attributes?.customers?.data;
      let assignString = '';

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople
          .map((people) => people?.attributes?.name)
          .join(', ');
      } else {
        assignString = t.common.na;
      }
      teams_data.customer = assignString;

      let teamsMember = fileData[i].attributes?.users?.data;
      let teamsMemberString = '';
      if (teamsMember?.length > 0) {
        teamsMemberString = teamsMember
          .map(
            (people) =>
              `${people?.attributes?.firstName || ''} ${people.attributes
                .lastName || ''}`
          )
          .join(', ');
      } else {
        teamsMemberString = t.common.na;
      }
      teams_data.teamMember = teamsMemberString;

      teams_data.createdBy = fileData[i]?.attributes?.createdByUser?.data
        ? fileData[i]?.attributes?.createdByUser.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        teams_data.id,
        teams_data.name,
        // teams_data.description,
        teams_data.superior,
        teams_data.locations,
        teams_data.customer,
        teams_data.teamMember,
        // teams_data.createdBy,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 35 }, // Procedure Items column
      { wch: 35 }, // Created By column
      { wch: 35 }, // Created At column
      { wch: 25 }, // Updated At column
    ];
    DownloadExcelFileWithData(headers, csvData, csvColumnWidths, t.teams.title);
  };

  const generatePDF = async () => {
    const hasValues = Object.values(filterOptions).some(
      (option) => option.length > 0
    );

    if (!hasValues && searchTitle == '') {
      let mydata = await dispatch(fetchAllTeamsData({ organisationId, userRole, currentUserId }));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let teams_data = [];

      teams_data.id = fileData[i].id;
      teams_data.name = fileData[i].attributes.name || t.common.na;

      teams_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description.replace(/\s+/g, ' ').trim()
        : t.common.na;
      teams_data.superior = fileData[i]?.attributes?.superior?.data
        ? fileData[i]?.attributes.superior?.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes.superior?.data?.attributes?.lastName
        : t.common.na;

      let locationsData = fileData[i].attributes.locations?.data;
      let locationsString = '';

      if (locationsData?.length > 0) {
        locationsString = locationsData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        locationsString = t.common.na;
      }

      teams_data.locations = locationsString;

      let assigneePeople = fileData[i].attributes?.customers?.data;
      let assignString = '';

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople
          .map((people) => people?.attributes?.name)
          .join(', ');
      } else {
        assignString = t.common.na;
      }
      teams_data.customer = assignString;

      let teamsMember = fileData[i].attributes?.users?.data;
      let teamsMemberString = '';
      if (teamsMember?.length > 0) {
        teamsMemberString = teamsMember
          .map(
            (people) =>
              `${people?.attributes?.firstName || ''} ${people.attributes
                .lastName || ''}`
          )
          .join(', ');
      } else {
        teamsMemberString = t.common.na;
      }
      teams_data.teamMember = teamsMemberString;

      teams_data.createdBy = fileData[i]?.attributes?.createdByUser?.data
        ? fileData[i]?.attributes?.createdByUser.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        teams_data.id,
        teams_data.name,
        // teams_data.description,
        teams_data.superior,
        teams_data.locations,
        teams_data.customer,
        teams_data.teamMember,
        // teams_data.createdBy,
      ]);
    }
    const columnWidths = [, 30, 30];

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(headers, csvData, t.teams.title, columnWidths, 'A3');
  };

  return (
    <>
      <div className="filters">
        <div className="text-right mb-5 pb-5 d-flex justify-content-between align-items-center views">
          <div className="list-view-option">
            <p className={`single-view ${role == "requester" ? "d-none" : ""}`}>
              <NavLink to="/people">{t.people.title}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/teams">{t.people.filter.teams}</NavLink>
            </p>
          </div>

          {data?.length > 0 ? (
            <div className="table-searchbar d-flex justify-content-end">
              <div className="work-order-upload">
                <span className="search-svg">
                  <SearchIcon />
                </span>
                <Input
                  icon={{ name: 'search' }}
                  placeholder={t.teams.filter.search}
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {data?.length > 0 ? (
        <div className="filters-wrapper justify-content-between">
          <div className="flexbox table-right-dropdown d-flex ">
           
          



            {/* supervisor */}
            <WorkOrderSearchDropdown
              title={t.people.table.supervisor}
              options={filtersTrigger.superior.options}
              selectedValues={filterOptions.superior}
              searchValue={filterSearch.superior}
              handleSearchValue={handleSearchSupervisorChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.superior, value.value);

                setFilterOptions({
                  ...filterOptions,
                  superior: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  superior: '',
                });
              }}
            />

            <CategoryDropdown
              title={t.teams.team_info_form.customer}
              issearch={true}
              options={filtersTrigger.customers.options}
              searchValue={filterSearch.customer}
              handleSearchValue={handleSearchAssigneesChange}
              selectedValues={filterOptions.customers}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    customers: [...filterOptions.customers, e.target.value],
                  });
                  // setFilterSearch({
                  //   ...filterSearch,
                  //   customer: '',
                  // });
                } else {
                  let filteredLocations = filterOptions.customers.filter(
                    (el) => el !== e.target.value
                  );
                  setFilterOptions({
                    ...filterOptions,
                    customers: filteredLocations,
                  });
                  // setFilterSearch({
                  //   ...filterSearch,
                  //   customer: '',
                  // });
                }
              }}
              icon={false}
              image={true}
            />
      <LocationSearchDropdown
            title={t.workOrders.table.location}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            searchValue={filterSearch.location}
            handleSearchValue={handleSearchLocationChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.locations, value.value);
              setFilterOptions({
                ...filterOptions,
                locations: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                location: "",
              });
            }}
          />
          </div>

          <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ">
            <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle
              style={{ position: "relative", cursor: "pointer" }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              color="primary"
              className="option-toggle"
            >
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "ar" ? "top right" : "top left"}
                show={showTooltip}
                textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                fontSize="12px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="4px 4px 4px 8px"
                borderRadius="5px"
                static
                moveRight="10px"
                moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
              >
                <span className="work-order-tooltip">
                  {t.common.page_action}
                </span>
              </ToolTip>
              <ActionBtnIcon />{" "}
            </DropdownToggle>
              <DropdownMenu className={selectedLanguage == 'ar' ? 'rtl' : ''}>
                {/* {role !== 'view_only' ? (
                  <>
                    <DropdownItem>
                      <ShareIcon /> {t.common.share}
                    </DropdownItem>
                    <DropdownItem className="divider" />
                  </>
                ) : null} */}
                <DropdownItem className="divider" />
                <DropdownItem onClick={() => downloadXLS()}>
                  <DownloadIcon /> {t.common.download_sheet}
                </DropdownItem>
                {/* <DropdownItem className="divider" />
                <DropdownItem onClick={() => generatePDF()}>
                  <PdfIcon /> {t.common.download_pdf}
                </DropdownItem> */}
                {/* <DropdownItem onClick={() => setAssetUploadModal(true)}>
                <UploadIcon /> Upload
              </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Filters;
