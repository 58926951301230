import { filter } from 'lodash';
import React, { useEffect, useState, createRef, useRef } from 'react';
import _ from 'lodash';
import {
  Popup,
  Form,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'semantic-ui-react';

import Quagga from '@ericblade/quagga2';
import QrScanner from 'qr-scanner';
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png"; 

import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import ToolTip from 'react-power-tooltip';

import {
  getAllLocations,
  getUsersByRole,
  getAllCustomers,
  IMAGES_URL,
  toggle,
  getAllVendros,
  getAllCategories,
  getAllAssets,
  getAllTeams,
  getAllAreaLocations,
  getAllAreaByLocationId,
  getAllParentLocations,
  getAllAssetsOption,
} from '../../config/functions';

import md5 from 'md5';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
// import QrScanner from 'qr-scanner';
import { toast } from 'react-toastify';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import { fetchAllVendorData } from '../../../redux/reducers/vendorReducer';
import {
  CategoryElectrical,
  CategoryMechanical,
  CategoryRefrigeration,
  CategorySafety,
  SearchIcon,
  DownloadIcon,
  ActionBtnIcon,
  CloseIcon,
  DeleteIcon,
  QRcodeIconInactive,
  BarcodeIcon,
  TextSnippet,
  AddIconWithCircle,
  PdfIcon,
  UploadIcon,
  NewXlsIcon,
  NewSheetIcon,
} from '../../../Assets/Icons/svg';
import {
  fetchAllPartsData,
  fetchPartsWithFilter,
} from '../../../redux/reducers/partReducer';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import WorkOrderSearchDropdown from '../../components/WorkOrder/WorkOrderSearchDropdown';
import QRCode from 'react-qr-code';
import QRComponentToPrint from './preview/qrCodeComponent';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import PartsBulkUploadModal from '../../components/modal/part/UploadBulkPartsModal';
import PartsBulkUploadResult from '../../components/modal/part/PartsBulkUploadResult';
import LocationSearchDropdown from '../../components/WorkOrder/LocationSearchDropdown';
const Filters = ({
  data,
  setDataByFilter,
  setFilterOptions,
  filterOptions,
  setSearchTitle,
  searchTitle,
  debounceValue,
  setloader,
  loader,
  sortData,
  setSortData,
  fileData = [],
  role,allIds
}) => {
  const t = useSelector(selectTranslations);
  const [assetUploadResultModal, setAssetUploadResultModal] = useState(false);
  const [bulkUploadResultData, setBulkUploadResultData] = useState([]);
  const [bulkUploadResultSuccessData, setBulkUploadResultSuccessData] = useState();
  const [assetUploadModal, setAssetUploadModal] = useState(false);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const partTypeOptions = [
    {
      key: 0,
      text: t.common.all,
      value: '',
      color: '',
    },
    {
      key: 1,
      text: t.parts.type.critical,
      value: 'critical',
    },
    {
      key: 2,
      text: t.parts.type.non_critical,
      value: 'non-critical',
    },
  ];

  const { userPermissions, checkForPermission } = usePermissionsSimplified();

  const [activeBtn, setActiveBtn] = useState(2);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const lang = useSelector((state) => state.i18n.lang);
  const [openQRmodal, setOpenQRmodal] = useState(false);
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);

  const [selectedQrcode, setSelectedQrcode] = useState(null);

  const [filtersTrigger, setFiltersTrigger] = useState({
    vendors: {
      initOptions: [],
      options: [],
    },
    teams: {
      initOptions: [],
      options: [],
    },
    assets: {
      initOptions: [],
      options: [],
    },
    categories: {
      initOptions: [],
      options: [],
    },
    locations: {
      initOptions: [],
      options: [],
    },
    area: {
      initOptions: [],
      options: [],
    },

    status: {
      options: [
        {
          key: 1,
          text: t.common.all,
          value: '',
          color: '',
        },
        {
          key: 2,
          text: t.parts.status.instock,
          value: 'inStock',
        },
        {
          key: 2,
          text: t.parts.status.low_stock,
          value: 'lowStock',
        },
        {
          key: 2,
          text: t.parts.status.out_of_stock,
          value: 'outOfStock',
        },
        {
          key: 2,
          text: t.parts.status.non_stock,
          value: 'nonStock',
        },
      ],
    },

    partType: {
      options: [
        {
          key: 0,
          text: t.common.all,
          value: '',
          color: '',
        },
        {
          key: 1,
          text: t.parts.type.critical,
          value: 'critical',
        },
        {
          key: 2,
          text: t.parts.type.non_critical,
          value: 'non-critical',
        },
      ],
    },

    wed: {
      options: [
        { key: 0, text: 'Any Date', value: 0 },
        { key: 1, text: 'Today', value: 1 },
        { key: 2, text: 'Tomorrow', value: 2 },
        { key: 3, text: 'Next 7 Days', value: 3 },
        { key: 5, text: 'This Month', value: 4 },
        { key: 7, text: 'This Year', value: 5 },
        { key: 13, text: 'Expired', value: 6 },
      ],
    },

    createdAt: {
      options: [
        { key: 0, text: t.parts.created_at_options.any_date, value: 0 },
        { key: 1, text: t.parts.created_at_options.today, value: 1 },
        { key: 2, text: t.parts.created_at_options.this_month, value: 2 },
        { key: 3, text: t.parts.created_at_options.this_year, value: 3 },
        { key: 4, text: t.parts.created_at_options.last_7_days, value: 4 },
        { key: 5, text: t.parts.created_at_options.last_month, value: 5 },
        { key: 7, text: t.parts.created_at_options.last_year, value: 6 },
      ],
    },
  });

  const [filterSearch, setFilterSearch] = useState({
    customer: '',
    assets: '',
    locations: '',
    vendor: '',
    teams: '',
    area: '',
    category: '',
    barcode: '',
    categories: '',
  });

  const [selectedFile, setSelectedFile] = useState({});

  const [newData, setNewData] = useState(data);

  const [openArea, setOpenArea] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openBarcode, setOpenBarcode] = useState(false);
  const [openWED, setOpenWED] = useState(false);
  const [openVendors, setOpenVendors] = useState(false);
  const [openCustomers, setOpenCustomers] = useState(false);
  const [openCreatedAt, setOpenCreatedAt] = useState(false);
  const [openCreatedBy, setOpenCreatedBy] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);

  const fileInputRef = useRef(null);

  const headers = [
    { label: t.workOrders.table.ID, key: 'id' },
    { label: t.parts.table.name, key: 'name' },
    { label: t.parts.filter.partType, key: 'partType' },
    { label: t.parts.table.avg_unit_cost, key: 'avg_unit_cost' },
    { label: t.parts.table.qty_in_stock, key: 'qty_in_stock' },
    { label: t.parts.table.serialNumber, key: 'serialNumber' },
    { label: t.parts.table.vendor, key: 'vendor' },
    { label: t.workRequests.table.asset, key: 'asset' },
    { label: t.parts.table.team, key: 'teams' },
    { label: t.assets.form.category, key: 'category' },
    { label: t.parts.table.created_on, key: 'created_on' },
    { label: t.parts.table.updated_on, key: 'updated_on' },
  ];

  /* Fetch filters data */
  useEffect(() => {
    (async function() {
      let the_options_asset = [];
      let the_options_vendor = [];
      let the_options_category = [];
      let the_options_teams = [];
      let the_options = [];
      let parentOptions = [];
      let subOptions = [];
  
      const fetchDataLocation = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      );

      if (fetchDataLocation?.response?.length > 0) {
        fetchDataLocation.response.forEach((el) => {
          const hasStoreArea = el.attributes.areas.data.some(
              (area) => area?.attributes?.isStore
          );
          const isParentIncluded = allIds?.includes(el.id);
      
          if (role === "super_admin" || role === "admin") {
              // Logic for super_admin or admin
              if (hasStoreArea) {
                  parentOptions.push({
                      key: el.id,
                      value: el.id,
                      text: el.attributes.name,
                      image: { src: location_icon },
   className: "main-location-dropdown-option",
                      disabled: false,
                      area: el?.attributes?.areas?.data || [],
                  });
      
                  el.attributes.subLocations.data.forEach((subEl) => {
                      const hasStoreSubArea = subEl.attributes.areas.data.some(
                          (area) => area?.attributes?.isStore
                      );
                      if (hasStoreSubArea) {
                          subOptions.push({
                              key: subEl.id,
                              value: subEl.id,
                              text: subEl.attributes.name,
                              image: { src: sub_location_icon },
                              className: "sub-location-dropdown-option",
                              parentId: el.id,
                              area: subEl?.attributes?.areas?.data || [],
                          });
                      }
                  });
              } else {
                  el.attributes.subLocations.data.forEach((subEl) => {
                      const hasStoreSubArea = subEl.attributes.areas.data.some(
                          (area) => area?.attributes?.isStore
                      );
                      if (hasStoreSubArea) {
                          parentOptions.push({
                              key: el.id,
                              value: el.id,
                              text: el.attributes.name,
                              image: { src: location_icon },
   className: "main-location-dropdown-option",
                              disabled: true,
                              area: el?.attributes?.areas?.data || [],
                          });
                          subOptions.push({
                              key: subEl.id,
                              value: subEl.id,
                              text: subEl.attributes.name,
                              image: { src: sub_location_icon },
                              className: "sub-location-dropdown-option",
                              parentId: el.id,
                              area: subEl?.attributes?.areas?.data || [],
                          });
                      }
                  });
              }
          } else {
              // Logic for other roles
              if (hasStoreArea && isParentIncluded) {
                parentOptions.push({
                    key: el.id,
                    value: el.id,
                    text: el.attributes.name,
                    image: { src: location_icon },
   className: "main-location-dropdown-option",
                    disabled: false,
                    area: el?.attributes?.areas?.data || [],
                });
    
                el.attributes.subLocations.data.forEach((subEl) => {
                 
                    const hasStoreSubArea = subEl.attributes.areas.data.some(
                        (area) => area?.attributes?.isStore
                    );
                   
                    const isSubIncluded = allIds?.includes(subEl.id);
                 
                    if (hasStoreSubArea && isSubIncluded) {
                        subOptions.push({
                            key: subEl.id,
                            value: subEl.id,
                            text: subEl.attributes.name,
                            image: { src: sub_location_icon },
                            className: "sub-location-dropdown-option",
                            parentId: el.id,
                            area: subEl?.attributes?.areas?.data || [],
                        });
                    }
                });
            } else {
                el.attributes.subLocations.data.forEach((subEl) => {
                    const hasStoreSubArea = subEl.attributes.areas.data.some(
                        (area) => area?.attributes?.isStore
                    );
                    const isSubIncluded = allIds?.includes(subEl.id);
                    if (hasStoreSubArea && isSubIncluded) {
                        parentOptions.push({
                            key: el.id,
                            value: el.id,
                            text: el.attributes.name,
                            image: { src: location_icon },
   className: "main-location-dropdown-option",
                            disabled: true,
                            area: el?.attributes?.areas?.data || [],
                        });
                        subOptions.push({
                            key: subEl.id,
                            value: subEl.id,
                            text: subEl.attributes.name,
                            image: { src: sub_location_icon },
                            className: "sub-location-dropdown-option",
                            parentId: el.id,
                            area: subEl?.attributes?.areas?.data || [],
                        });
                    }
                });
            }
          }
      });
  
        // Sort sub-options alphabetically
        subOptions.sort((a, b) => a.text.localeCompare(b.text));
  
        // Merge parent options with sorted sub-options
        parentOptions.forEach((parentOption) => {
          the_options.push(parentOption);
          subOptions.forEach((subOption) => {
            if (subOption.parentId === parentOption.key) {
              the_options.push(subOption);
            }
          });
        });
  
        // Update the options state
     
      }


      const fetchData = await getAllAssetsOption(
        organisationId,
        "all_asset",
        1,
        9999,
        data?.locations
          ? [data?.locations]
          : !(role === "super_admin" || role === "admin")
          ? data?.locations
            ? [data?.locations]
            : allIds
          : [],
        [],
        "",
        "",
        [],
        ["name:asc"]
      );
  
      let assetOptions = [];
      let parentToChildMap = new Map();
      let childToGrandChildMap = new Map();
      let hierarchicalOptions = [];
      if (fetchData?.response?.length > 0) {
        fetchData.response.forEach((el) => {
          // Default to active if the user is admin or super_admin
          const isDisposed = el?.attributes?.status === "dispose";
  
  
  
          const isActive = isDisposed
            ? !isDisposed
            : !(role === "super_admin" || role === "admin")
            ? data?.locations
              ? data?.locations == el?.attributes?.location?.data?.id
              : allIds?.includes(el?.attributes?.location?.data?.id)
            : data?.locations
            ? data?.locations == el?.attributes?.location?.data?.id
            : !isDisposed; // In
          // (roleData === "super_admin" || roleData === "admin" || allIds?.includes(el?.attributes?.location?.data?.id)) &&
          // !isDisposed; // Include status check for active
          // const isActive = roleData === "super_admin" || roleData === "admin" ||
          //                  allIds?.includes(el?.attributes?.location?.data?.id);
  
          // Create the parent asset option
          const parentAsset = {
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: parent_icon }, // Use a location icon for the main asset
            className: "main-location-dropdown-option",
            disabled: !isActive, // Disable only for non-admin roles
          };
  
          parentToChildMap.set(el.id, []);
          assetOptions.push(parentAsset);
  
          // Collect child assets
          if (el?.attributes?.storedAssets?.length > 0) {
            el.attributes.storedAssets.forEach((childEl) => {
              if (childEl?.childAsset?.data) {
                const childAsset = childEl.childAsset.data;
                const childIsDisposed =
                  childAsset?.attributes?.status === "dispose";
  
                const childIsActive = childIsDisposed
                  ? !childIsDisposed
                  : !(role === "super_admin" || role === "admin")
                  ? data?.locations
                    ? data?.locations ==
                      childAsset?.attributes?.location?.data?.id
                    : allIds?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                  : data?.locations
                  ? data?.locations == childAsset?.attributes?.location?.data?.id
                  : !childIsDisposed; // In
  
                const childOption = {
                  key: childAsset.id,
                  value: childAsset.id,
                  text: `${childAsset.attributes.name}`,
                  image: { src: child_icon }, // Use a sub-location icon for child assets
                  className: "sub-location-dropdown-option",
                  parentId: el.id,
                  disabled: !childIsActive, // Disable child based on role
                };
  
                parentToChildMap.get(el.id).push(childOption);
  
                // Initialize a child-to-grandchild mapping for this child
                childToGrandChildMap.set(childAsset.id, []);
  
                // Collect grandchild assets under this child
                if (childEl?.grandChildAsset?.data?.length > 0) {
                  childEl.grandChildAsset.data.forEach((grandChildEl) => {
                    const grandChildIsDisposed =
                      grandChildEl?.attributes?.status === "dispose";
  
                    const grandChildIsActive = grandChildIsDisposed
                      ? !grandChildIsDisposed
                      : !(role === "super_admin" || role === "admin")
                      ? data?.locations
                        ? data?.locations ==
                          grandChildEl?.attributes?.location?.data?.id
                        : allIds?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                      : data?.locations
                      ? data?.locations ==
                        grandChildEl?.attributes?.location?.data?.id
                      : !grandChildIsDisposed;
  
                    const grandChildOption = {
                      key: grandChildEl.id,
                      value: grandChildEl.id,
                      text: `${grandChildEl.attributes.name}`,
                      image: { src: gchild_icon }, // Optionally, use a different icon for grandchild
                      className: "grandchild-asset-dropdown-option",
                      parentId: el.id, // Track the top-level parent asset
                      childParentId: childAsset.id, // Track the immediate child parent ID
                      disabled: !grandChildIsActive, // Disable grandchild based on role
                    };
  
                    childToGrandChildMap
                      .get(childAsset.id)
                      .push(grandChildOption);
                  });
                }
              }
            });
          }
        });
  
        // Sort child and grandchild options alphabetically within each parent and child context
        parentToChildMap.forEach((children) => {
          children.sort((a, b) => a.text.localeCompare(b.text));
        });
  
        childToGrandChildMap.forEach((grandChildren) => {
          grandChildren.sort((a, b) => a.text.localeCompare(b.text));
        });
  
        // Combine all the options into a hierarchical structure
        
  
        // Traverse each parent option and append its children and grandchildren
        assetOptions.forEach((parentOption) => {
          hierarchicalOptions.push(parentOption);
  
          // Add children under this parent
          const children = parentToChildMap.get(parentOption.key) || [];
          children.forEach((childOption) => {
            hierarchicalOptions.push(childOption);
  
            // Add grandchildren under this child
            const grandChildren = childToGrandChildMap.get(childOption.key) || [];
            grandChildren.forEach((grandChildOption) => {
              hierarchicalOptions.push(grandChildOption);
            });
          });
        });
  
        // setOptions((prev) => {
        //   return {
        //     ...prev,
        //     assets: hierarchicalOptions,
        //   };
        // });
  
        // return hierarchicalOptions;
      }

      const fetchDataCustomer = await getAllVendros(organisationId,'',1,9999,!(role === "super_admin" || role === "admin") ? allIds : []);
      if (fetchDataCustomer?.response) {
        fetchDataCustomer.response.forEach((el) => {
          the_options_vendor.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.logo.data
                ? IMAGES_URL + el.attributes.logo.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + ' '),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      const fetchTeamsData = await getAllTeams(organisationId,'',1,9999,[],[],[],!(role === "super_admin" || role === "admin") ? allIds : []);
      if (fetchTeamsData?.response) {
        fetchTeamsData.response.map((el) => {
          the_options_teams.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: LetteredAvatarImageUrl(el.attributes.name),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      const fetchDataCategory = await getAllCategories(organisationId);

      if (fetchDataCategory?.response) {
        fetchDataCategory.response.forEach((el) => {
          the_options_category.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: `${el.attributes.name}`,
          });
        });
      }

      // const fetchDataLocation = await getAllParentLocations(
      //   organisationId,
      //   '',
      //   1,
      //   99999
      // );

      // if (fetchDataLocation.response) {
      //   fetchDataLocation.response.forEach((el) => {
      //     the_options_location.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: `${el.attributes.name}`,
      //     });
      //   });
      // }

      setFiltersTrigger({
        ...filtersTrigger,
        vendors: {
          ...filtersTrigger.vendors,
          initOptions: the_options_vendor,
          options: the_options_vendor,
        },
        assets: {
          ...filtersTrigger.assets,
          initOptions: hierarchicalOptions,
          options: hierarchicalOptions,
        },
        categories: {
          ...filtersTrigger.categories,
          initOptions: the_options_category,
          options: the_options_category,
        },
        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options,
          options: the_options,
        },
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options_teams,
          options: the_options_teams,
        },
      });
    })();
  }, []);

  // Check if status, partType, or serialNumber is not empty
  const checkSpecialArraysNotEmpty = () => {
    return (
      filterOptions.status[0] !== '' ||
      filterOptions.partType[0] !== '' ||
      filterOptions.serialNumber !== ''
    );
  };
  /*
   * The `closeAllFilters` function closes all filter options by setting their respective states to
   * false.
   */

  const closeAllFilters = () => {
    setOpenCategory(false);
    setOpenArea(false);
    setOpenModel(false);
    setOpenBarcode(false);
    setOpenWED(false);
    setOpenVendors(false);
    setOpenCustomers(false);
    setOpenCreatedBy(false);
    setOpenCreatedAt(false);
  };

  /*
   * The function `handleBrowseButtonClick` triggers a click event on a file input element
   */
  const handleBrowseButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  /*
   * The `generatePDF` function fetches data, processes it, and generates a PDF file with the formatted
   * data.
   */

  const generatePDF = async () => {
    const arraysNotEmpty = Object.entries(filterOptions).some(
      ([key, value]) =>
        key !== 'partType' &&
        key !== 'status' &&
        key !== 'serialNumber' &&
        Array.isArray(value) &&
        value.length > 0
    );

    const statusCheck = checkSpecialArraysNotEmpty(filterOptions);


    if (!arraysNotEmpty && !statusCheck && searchTitle == '') {
      let mydata = await dispatch(fetchAllPartsData({organisationId,role,allIds}));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    let csvData = [];

    let part_data = [];

    for (let i = 0; i < fileData.length; i++) {
      let part_data = [];

      part_data.id = fileData[i].id;
      part_data.title = fileData[i].attributes.name || t.common.na;

      const partTypeOptionsLabel = partTypeOptions.find(
        (status) => status?.value === fileData[i]?.attributes?.type
      );

      part_data.partType = partTypeOptionsLabel?.text || t.common.na;
      part_data.avg_unit_cost = fileData[i]?.attributes?.avgUnitCost
        ? fileData[i]?.attributes?.avgUnitCost
        : '0';
      part_data.qty_in_stock = fileData[i]?.attributes?.qtyInStock
        ? fileData[i]?.attributes?.qtyInStock
        : '0';
      part_data.serialNumber = fileData[i]?.attributes?.serialNumber
        ? fileData[i]?.attributes?.serialNumber
        : t.common.na;

      let vendors = fileData[i].attributes?.vendors?.data;
      let vendorString = '';

      if (vendors?.length > 0) {
        vendorString = vendors
          .map((people) => people?.attributes?.name)
          .join(', ');
      } else {
        vendorString = t.common.na;
      }
      part_data.vendor = vendorString;

      let assets = fileData[i].attributes?.assets?.data;
      let assetsString = '';

      if (assets?.length > 0) {
        assetsString = assets
          .map((people) => people?.attributes?.name)
          .join(', ');
      } else {
        assetsString = t.common.na;
      }
      part_data.asset = assetsString;

      part_data.team = fileData[i].attributes?.teams?.data?.attributes?.name
        ? fileData[i].attributes?.teams?.data?.attributes?.name
        : t.common.na;
      part_data.category = fileData[i].attributes?.category?.data?.attributes
        ?.name
        ? fileData[i].attributes?.category?.data?.attributes?.name
        : t.common.na;

      part_data.created_on = fileData[i]?.attributes?.createdAt
        ? moment(fileData[i].attributes?.createdAt).format('YYYY-MM-DD LT')
        : t.common.na;

      part_data.updated_on = fileData[i]?.attributes?.updatedAt
        ? moment(fileData[i].attributes?.updatedAt).format('YYYY-MM-DD LT')
        : t.common.na;

      csvData.push([
        part_data.id,
        part_data.title,
        part_data.partType,
        part_data.avg_unit_cost,
        part_data.qty_in_stock,
        part_data.serialNumber,
        part_data.vendor,
        part_data.asset,
        part_data.team,
        part_data.category,
        part_data.created_on,
        part_data.updated_on,
      ]);
    }

    const columnWidths = [10, 22, 30, 25, 25, 30, 50, 50, 30, 30, 25, 25];
    DownloadPdfFileWithData(
      headers,
      csvData,
      t.parts.tab.first,
      columnWidths,
      'A2'
    );
  };

  /*
   * The `downloadXLS` function fetches data, processes it, and then downloads it as an Excel file with
   * specific column widths.
   */
  const downloadXLS = async () => {
    const arraysNotEmpty = Object.entries(filterOptions).some(
      ([key, value]) =>
        key !== 'partType' &&
        key !== 'status' &&
        key !== 'serialNumber' &&
        Array.isArray(value) &&
        value.length > 0
    );

    const statusCheck = checkSpecialArraysNotEmpty(filterOptions);


    if (!arraysNotEmpty && !statusCheck && searchTitle == '') {
      let mydata = await dispatch(fetchAllPartsData({organisationId,role,allIds}));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let part_data = [];

      part_data.id = fileData[i].id;
      part_data.title = fileData[i].attributes.name || t.common.na;

      const partTypeOptionsLabel = partTypeOptions.find(
        (status) => status?.value === fileData[i]?.attributes?.type
      );

      part_data.partType = partTypeOptionsLabel?.text || t.common.na;
      part_data.avg_unit_cost = fileData[i]?.attributes?.avgUnitCost
        ? fileData[i]?.attributes?.avgUnitCost
        : '0';
      part_data.qty_in_stock = fileData[i]?.attributes?.qtyInStock
        ? fileData[i]?.attributes?.qtyInStock
        : '0';
      part_data.serialNumber = fileData[i]?.attributes?.serialNumber
        ? fileData[i]?.attributes?.serialNumber
        : t.common.na;

      let vendors = fileData[i].attributes?.vendors?.data;
      let vendorString = '';

      if (vendors?.length > 0) {
        vendorString = vendors
          .map((people) => people?.attributes?.name)
          .join(', ');
      } else {
        vendorString = t.common.na;
      }
      part_data.vendor = vendorString;

      let assets = fileData[i].attributes?.assets?.data;
      let assetsString = '';

      if (assets?.length > 0) {
        assetsString = assets
          .map((people) => people?.attributes?.name)
          .join(', ');
      } else {
        assetsString = t.common.na;
      }
      part_data.asset = assetsString;

      part_data.team = fileData[i].attributes?.teams?.data?.attributes?.name
        ? fileData[i].attributes?.teams?.data?.attributes?.name
        : t.common.na;
      part_data.category = fileData[i].attributes?.category?.data?.attributes
        ?.name
        ? fileData[i].attributes?.category?.data?.attributes?.name
        : t.common.na;

      part_data.created_on = fileData[i]?.attributes?.createdAt
        ? moment(fileData[i].attributes?.createdAt).format('YYYY-MM-DD LT')
        : t.common.na;

      part_data.updated_on = fileData[i]?.attributes?.updatedAt
        ? moment(fileData[i].attributes?.updatedAt).format('YYYY-MM-DD LT')
        : t.common.na;

      csvData.push([
        part_data.id,
        part_data.title,
        part_data.partType,
        part_data.avg_unit_cost,
        part_data.qty_in_stock,
        part_data.serialNumber,
        part_data.vendor,
        part_data.asset,
        part_data.team,
        part_data.category,
        part_data.created_on,
        part_data.updated_on,
      ]);
    }

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 25 }, // Title column
      { wch: 25 }, // Description column
      { wch: 20 }, // Category column
      { wch: 20 }, // Procedure Items column
      { wch: 30 }, // Created By column
      { wch: 30 }, // Created At column
      { wch: 30 }, // Updated At column
      { wch: 25 }, // Updated At column
      { wch: 25 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];

    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.parts.tab.first
    );
  };

  /* It is setting up a side effect that will run when the value of `filterSearch.assets` changes. */
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.assets), 'i');
    const isMatch = (result) => re.test(result.text);
    setFiltersTrigger({
      ...filtersTrigger,
      assets: {
        ...filtersTrigger.assets,
        options:
          filterSearch.assets.length > 0
            ? _.filter(filtersTrigger.assets.initOptions, isMatch)
            : filtersTrigger.assets.initOptions,
      },
    });
  }, [filterSearch.assets]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.locations), 'i');
    const isMatch = (result) => re.test(result.text);
    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        ...filtersTrigger.locations,
        options:
          filterSearch.locations.length > 0
            ? _.filter(filtersTrigger.locations.initOptions, isMatch)
            : filtersTrigger.locations.initOptions,
      },
    });
  }, [filterSearch.locations]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.vendor), 'i');
    const isMatch = (result) => {
      let ss = re.test(result.text);
    };

    let data = filtersTrigger.vendors.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      vendors: {
        ...filtersTrigger.vendors,
        options:
          filterSearch.vendor.length > 0
            ? data
            : filtersTrigger.vendors.initOptions,
      },
    });
  }, [filterSearch.vendor]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        ...filtersTrigger.teams,
        options:
          filterSearch.teams.length > 0
            ? _.filter(filtersTrigger.teams.initOptions, isMatch)
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  /*
   * The function `handleSearchTeamsChange` updates the `teams` property in the `filterSearch` state based on the value of the input element.
   */
  const handleSearchTeamsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      teams: e.target.value,
    });
  };
  /*
   * The function `handleSearchAssigneesChange` updates the `vendor` property in the `filterSearch` state based on the value of the input element.
   */
  const handleSearchAssigneesChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      vendor: e.target.value,
    });
  };

  /*
   * The function `handleSearchLocationChange` updates the `locations` property in the `filterSearch` object based on the value of the event target.
   */
  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      locations: e.target.value,
    });
  };

  useEffect(() => {
    (async function() {
      let the_options_area = [];
      if (filterOptions?.locations?.length > 0) {
        const fetchDataLocation = await getAllAreaByLocationId(
          organisationId,
          filterOptions.locations[0]
        );

        if (fetchDataLocation) {
          fetchDataLocation.forEach((el) => {
            the_options_area.push({
              key: `${el.id}`,
              value: `${el.id}`,
              text: `${el.attributes.name}`,
            });
          });
        }

        // console.log('THE COUNTRY SELECTED', the_options);

        setFiltersTrigger((prev) => {
          return {
            ...prev,

            area: {
              ...filtersTrigger.area,
              initOptions: the_options_area,
              options: the_options_area,
            },
          };
        });
      } else {
        setFiltersTrigger((prev) => {
          return {
            ...prev,

            area: {
              initOptions: [],
              options: [],
            },
          };
        });
      }
    })();
  }, [filterOptions.locations]);

  const handleSearchAssetsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      assets: e.target.value,
    });
  };

  const processImage = (imageData) => {
    QrScanner.scanImage(imageData)
      .then((result) => {
        setFilterSearch({
          ...filterOptions,
          serialNumber: result.split('-')[1],
        });
      })
      .catch((err) => {
        toast.error(t.parts.no_barcode);
        setSelectedQrcode(null);
        setSelectedFile({});
      });
  };




  /*
   * The `handleQRCode` function processes a QR code or barcode image to extract a serial number and update the filter search based on the active button state.
   */
  const handleQRCode = (setFilterSearch, file) => {
    

    setSelectedQrcode(file);
    if (activeBtn == 2) {
      QrScanner.scanImage(file)
        .then((result) => {
          console.log(result);
          setFilterSearch({
            ...filterOptions,
            serialNumber: result,
          });
        })
        .catch((err) => {
          toast.error(t.parts.no_barcode);
          setSelectedQrcode(null);
          setSelectedFile({});
        });
    } 
    else {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log('e: ', e);
        // Decode barcodes from the image
        Quagga.decodeSingle(
          {
            src: e.target.result,
            numOfWorkers: 0, // Needs to be set to 0 when used within a React component
            locate: true,
            // inputStream: {
            //   size: 2000, // Image size
            // },
            decoder: {
              readers: ['code_128_reader'], // Supported barcode formats
            },
          },
          (result) => {
            if (result && result.codeResult) {
              console.log('result.codeResult: ', result.codeResult);
              setFilterSearch({
                ...filterOptions,
                serialNumber: result.codeResult.code,
              });
            } else {
              toast.error(t.assets.no_barcode);
              setSelectedFile({});
            }
          }
        );
      };
      reader.readAsDataURL(file);
    }

    setOpenQRmodal(false);
  };

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.categories), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      categories: {
        initOptions: filtersTrigger.categories.initOptions,
        options:
          filterSearch.categories.length > 0
            ? _.filter(filtersTrigger.categories.initOptions, isMatch)
            : filtersTrigger.categories.initOptions,
      },
    });
  }, [filterSearch.categories]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.vendor), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      vendors: {
        initOptions: filtersTrigger.vendors.initOptions,
        options:
          filterSearch.vendor.length > 0
            ? _.filter(filtersTrigger.vendors.initOptions, isMatch)
            : filtersTrigger.vendors.initOptions,
      },
    });
  }, [filterSearch.vendor]);
  /* The above code is a React useEffect hook that is being used to fetch parts data with filters from an
API. When the component mounts or when the `filterOptions` or `debounceValue` change, the useEffect
hook will be triggered. Inside the useEffect, it sets the loader to true, then dispatches an
asynchronous action to fetch parts data with the specified filters. Once the data is fetched, it
sets the loader back to false. */

  useEffect(() => {
    (async function() {
      setloader(true);

      await dispatch(
        fetchPartsWithFilter({
          organisationId,
          searchName: debounceValue,
          page: '1',
          pageSize: '25',
          partType: filterOptions.partType,
          status: filterOptions.status,
          assets: filterOptions.assets,
          vendors: filterOptions.vendors,
          locations: filterOptions.locations,
          area: filterOptions.area,
          teams: filterOptions.teams,
          category: filterOptions.categories,
          serialNumber: filterOptions.serialNumber,
          sort: sortData,
          role,
          allIds
        })
      );

      setloader(false);
    })();
  }, [filterOptions, debounceValue]);

  return (
    <>
      <div className="filters">
        <div className="text-right mb-5 pb-5 d-flex justify-content-between align-items-center views flex-wrap-ans">
          <div className="list-view-option">
            <p className="single-view">
              <NavLink to="/parts" className="white-space-no">
                {t.parts.tab.first}
              </NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/sets">{t.parts.tab.second}</NavLink>
            </p>
          </div>

          <div className="table-searchbar d-flex flex-wrap-ans">
            <button
              className={`upload-btn mr-6 mb-2-css ${
                lang == 'ar' ? 'ml-6' : ''
              }`}
              onClick={() => setOpenQRmodal(true)}
            >
              {t.parts.upload_code}
            </button>
            <div className="work-order-upload">
              <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
                icon={{ name: 'search' }}
                placeholder={t.workOrders.filter.search}
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="filters-wrapper justify-content-between align-items-start">
        <div className="flexbox table-right-dropdown">
          <CategoryDropdown
            title={t.workOrders.table.status}
            options={filtersTrigger.status.options}
            selectedValues={filterOptions.status}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions((prevState) => {
                  return {
                    ...prevState,
                    status: [e.target.value],
                  };
                });
              }
            }}
            icon={false}
            image={false}
          />


<LocationSearchDropdown
              title={t.workOrders.table.location}
              options={filtersTrigger.locations.options}
              selectedValues={filterOptions.locations}
              searchValue={filterSearch.locations}
              handleSearchValue={handleSearchLocationChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.locations, value.value);
                setFilterOptions({
                  ...filterOptions,
                  locations: newArray,
                });
  
                setFilterSearch({
                  ...filterSearch,
                  location: "",
                });
              }}
            />

          <CategoryDropdown
            title={t.parts.form.area}
            options={filtersTrigger.area.options}
            selectedValues={filterOptions.area}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions((prevState) => {
                  return {
                    ...prevState,
                    area: [e.target.value],
                  };
                });
              } else {
                let filteredStatus = filterOptions.area.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  area: filteredStatus,
                });
              }
            }}
            icon={false}
          />
<LocationSearchDropdown
                     title={t.workRequests.table.asset}
                     options={filtersTrigger.assets.options}
                     selectedValues={filterOptions.assets}
                     searchValue={filterSearch.assets}
                     handleSearchValue={handleSearchAssetsChange}
                     onClick={(value) => {
                       let newArray = toggle(filterOptions.assets, value.value);
         
                       setFilterOptions({
                         ...filterOptions,
                         assets: newArray,
                       });
         
                       setFilterSearch({
                         ...filterSearch,
                         assets: '',
                       });
                     }}
            />

          <CategoryDropdown
            title={t.parts.filter.partType}
            options={filtersTrigger.partType.options}
            selectedValues={filterOptions.partType}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions((prevState) => {
                  return {
                    ...prevState,
                    partType: [e.target.value],
                  };
                });
              }
            }}
            icon={false}
            image={false}
          />

          {/* <WorkOrderSearchDropdown
            title={t.workRequests.table.asset}
            options={filtersTrigger.assets.options}
            selectedValues={filterOptions.assets}
            searchValue={filterSearch.assets}
            handleSearchValue={handleSearchAssetsChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.assets, value.value);

              setFilterOptions({
                ...filterOptions,
                assets: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                assets: '',
              });
            }}
          /> */}

          <WorkOrderSearchDropdown
            title={t.parts.table.vendor}
            options={filtersTrigger.vendors.options}
            searchValue={filterSearch.vendor}
            handleSearchValue={handleSearchAssigneesChange}
            selectedValues={filterOptions.vendors}
            onClick={(value) => {
              let newArray = toggle(filterOptions.vendors, value.value);

              setFilterOptions({
                ...filterOptions,
                vendors: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                vendors: '',
              });
            }}
          />

          <WorkOrderSearchDropdown
            title={t.people.filter.team}
            options={filtersTrigger.teams.options}
            selectedValues={filterOptions.teams}
            searchValue={filterSearch.teams}
            handleSearchValue={handleSearchTeamsChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.teams, value.value);

              setFilterOptions({
                ...filterOptions,
                teams: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                teams: '',
              });
            }}
          />

          <CategoryDropdown
            title={t.assets.form.category}
            options={filtersTrigger.categories.options}
            selectedValues={filterOptions.categories}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  categories: [...filterOptions.categories, e.target.value],
                });
              } else {
                let filteredCategories = filterOptions.categories.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  categories: filteredCategories,
                });
              }
            }}
            icon={false}
            image={false}
          />
        </div>

        <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2">
          <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle
              style={{ position: 'relative', cursor: 'pointer' }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              color="primary"
              className="option-toggle"
            >
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == 'ar' ? 'top right' : 'top left'}
                show={showTooltip}
                textBoxWidth={selectedLanguage == 'ar' ? '53px' : '87px'}
                arrowAlign={selectedLanguage == 'ar' ? 'center' : 'start'}
                fontSize="12px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == 'ar' ? 'Noto Kufi Arabic' : 'Roboto'
                }
                padding="4px 4px 4px 8px"
                borderRadius="5px"
                static
                moveRight="10px"
                moveUp={selectedLanguage == 'ar' ? '-1px' : '4px'}
              >
                <span className="work-order-tooltip">
                  {t.common.page_action}
                </span>
              </ToolTip>
              <ActionBtnIcon />{' '}
            </DropdownToggle>
            <DropdownMenu className={selectedLanguage == 'ar' ? 'rtl asset' : ''}>
              {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon /> {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}
              <DropdownItem className="divider" />
              <DropdownItem onClick={() => downloadXLS()}>
                <NewXlsIcon /> {t.common.excel_download}
              </DropdownItem>
              <DropdownItem className="divider" />
              <DropdownItem onClick={() => generatePDF()}>
                <PdfIcon /> {t.common.download_pdf}
              </DropdownItem>
              {userPermissions?.part?.edit ? (
                <>
                  <DropdownItem className="divider" />

                  <DropdownItem onClick={() => setAssetUploadModal(true)}>
                    <NewSheetIcon /> {t.common.upload_sheet}
                  </DropdownItem>
                </>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>

      <PartsBulkUploadModal
          isOpen={assetUploadModal}
          setModalOpenState={setAssetUploadModal}
          handleSuccess={(res,failedData) => {
            setBulkUploadResultData(failedData);
            setBulkUploadResultSuccessData(res);
            setAssetUploadResultModal(true);
            setAssetUploadModal(false);
            setFilterOptions({
              vendors: [],
              serialNumber: '',
              categories: [],
              teams: [],
              status: [''],
              partType: [''],
              assets: [],
              locations: [],
              area: [],
              serialNumber: '',
            });
          }}
        />

<PartsBulkUploadResult
          isOpen={assetUploadResultModal}
          setModalOpenState={setAssetUploadResultModal}
          data={bulkUploadResultData}
          totalCount={bulkUploadResultSuccessData}
        />
      

      {/* open QRcode modal*/}
      <Modal
        className="assets-modal qr-modal"
        size="tiny"
        open={openQRmodal}
        onClose={() => setOpenQRmodal(false)}
      >
        <div className="header-container">
          <span className="assets-header"> {t.parts.upload_code}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => setOpenQRmodal(false)}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content>
          <div className="flexbox barcode-btns mb-5">
            <button
              active={activeBtn == 1}
              onClick={() => setActiveBtn(1)}
              className={`qr-button ${activeBtn == 1 ? 'active' : ''}`}
            >
              <span>
                <BarcodeIcon />
              </span>{' '}
              {t.parts.form.barcode}
            </button>

            <button
              className={`qr-button ${activeBtn == 2 ? 'active' : ''}`}
              active={activeBtn == 2}
              onClick={() => setActiveBtn(2)}
            >
              <span className="mr-1">
                <QRcodeIconInactive />
              </span>
              {t.parts.form.qrcode}
            </button>
          </div>

          {!selectedFile?.name ? (
            <div
              onClick={handleBrowseButtonClick}
              className="warrenty-file-dropzone d-flex align-items-center justify-content-between"
            >
              <div className="d-flex">
                <span className="mt-1">
                  <TextSnippet />
                </span>
                <div className="pl-8 pr-8">
                  <p className="p1 m-0">{t.workOrders.form.file}</p>
                  <p className="p2 m-0">{t.parts.form.attach_file}</p>
                </div>
              </div>
              <div>
                <AddIconWithCircle />
              </div>
              <input
                id="barcodePopup"
                type="file"
                hidden
                className="file-input"
                ref={fileInputRef}
                fluid
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length) {
                    setSelectedFile(e.target.files[0]);
                  }
                }}
              />
            </div>
          ) : null}

          <div className="modal-input d-block">
            {selectedFile?.name ? (
              <>
                <label
                  className={`text-label ${
                    selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <div className={'mt-3 mb-3'}>
                    <span className="file-label">{selectedFile?.name}</span>
                    <span
                      className={`${
                        lang == 'ar' ? 'c-pointer mr-3' : 'c-pointer ml-3'
                      }`}
                      onClick={() => {
                        setSelectedFile({});
                        setFilterOptions({
                          ...filterOptions,
                          serialNumber: '',
                        });
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                  {activeBtn == 2 ? (
                    <div>
                      <QRCode
                        id="QRCode"
                        title="Parts QR Code"
                        value={true}
                        size={80}
                        disabled
                      />
                    </div>
                  ) : (
                    <div className="">
                      <QRComponentToPrint titleData={'ID'} isQRcode={false} />
                    </div>
                  )}
                </label>
              </>
            ) : null}
            <input
              id="barcodePopup"
              type="file"
              hidden
              className="file-input"
              ref={fileInputRef}
              fluid
              accept="image/*,application/pdf"
              onChange={(e) => {
                if (e.target.files && e.target.files.length) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />
          </div>
        </Modal.Content>
        {selectedFile?.name ? (
          <div
            className={`modal-button-container ${
              selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
            }`}
          >
            <button
              onClick={() => setOpenQRmodal(false)}
              className="modal-form-action-button black"
              style={{ fontWeight: '500' }}
            >
              {t.common.cancel}
            </button>

            <button
              onClick={() => handleQRCode(setFilterOptions, selectedFile)}
              className="modal-form-action-button blue"
            >
              {t.common.view}
            </button>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default Filters;
