import { filter } from 'lodash';
import React, { useEffect, useState, createRef } from 'react';
import _ from 'lodash';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
  Popup,
  Form,
} from 'semantic-ui-react';
import location_icon from "../../../Assets/Icons/loc_option_parent.png";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import { toast } from 'react-toastify';

import {
  getAllLocations,
  getAllCustomers,
  getAllTeams,
  humanize,
  toggle,
  getAllParentLocations,
} from '../../config/functions';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { selectTranslations } from '../../config/i18n/slice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Country, State, City } from 'country-state-city';
import {
  ActionBtnIcon,
  DownChevron,
  DownloadIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  UploadIcon,
  WhiteDownChevron,
} from '../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import ToolTip from "react-power-tooltip";
import {
  fetchAllCustomerData,
  fetchCustomerWithFilter,
} from '../../../redux/reducers/customerReducer';
import LocationSearchDropdown from '../../components/WorkOrder/LocationSearchDropdown';

const Filters = ({
  data,
  setDataByFilter,
  hasStatus,
  fileData = [],
  setFilterOptions,
  filterOptions,
  setloader,
  searchTitle,
  setSearchTitle,
  debounceValue,
  debounceRate,
  sortData,
  role,
  allIds
}) => {
  const t = useSelector(selectTranslations);

  const dispatch = useDispatch();

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [filtersTrigger, setFiltersTrigger] = useState({
    countries: {
      initOptions: [],
      options: [],
    },
    cities: {
      initOptions: [],
      options: [],
    },
    locations: {
      initOptions: [],
      options: [],
    },
    createdAt: {
      options: [
        { key: 0, text: t.customers.created_at_options.any_date, value: 0 },
        { key: 1, text: t.customers.created_at_options.today, value: 1 },
        { key: 2, text: t.customers.created_at_options.this_month, value: 2 },
        { key: 3, text: t.customers.created_at_options.this_year, value: 3 },
        { key: 4, text: t.customers.created_at_options.last_7_days, value: 4 },
        { key: 5, text: t.customers.created_at_options.last_month, value: 5 },
        { key: 7, text: t.customers.created_at_options.last_year, value: 6 },
      ],
    },
    updatedAt: {
      options: [
        { key: 0, text: t.customers.created_at_options.any_date, value: 0 },
        { key: 1, text: t.customers.created_at_options.today, value: 1 },
        { key: 2, text: t.customers.created_at_options.this_month, value: 2 },
        { key: 3, text: t.customers.created_at_options.this_year, value: 3 },
        { key: 4, text: t.customers.created_at_options.last_7_days, value: 4 },
        { key: 5, text: t.customers.created_at_options.last_month, value: 5 },
        { key: 7, text: t.customers.created_at_options.last_year, value: 6 },
      ],
    },
    dueDate: {
      options: [
        { key: 0, text: 'Any Date', value: 'any_day' },
        { key: 1, text: 'Today', value: 'today' },
        { key: 2, text: 'Tomorrow', value: 'tomorrow' },
        { key: 3, text: 'Next 7 Days', value: 'next_7_days' },
        { key: 4, text: 'Next 30 Days', value: 'next_30_days' },
        { key: 5, text: 'This Month', value: 'this_month' },
        { key: 6, text: 'This Quarter', value: 'this_quarter' },
        { key: 7, text: 'This Year', value: 'this_year' },
        { key: 8, text: 'Yesterday', value: 'yesterday' },
        { key: 9, text: 'Last Week', value: 'last_week' },
        { key: 10, text: 'Last Month', value: 'last_month' },
        { key: 11, text: 'Last Quarter', value: 'last_quarter' },
        { key: 12, text: 'Last Year', value: 'last_year' },
        { key: 13, text: 'Past Due', value: 'past_due' },
        { key: 14, text: 'Unscheduled', value: 'unscheduled' },
      ],
    },
  });
  const [openCreatedAt, setOpenCreatedAt] = useState(false);
  const [openUpdatedAt, setOpenUpdatedAt] = useState(false);

  const [filterSearch, setFilterSearch] = useState({
    country: '',
    city: '',
    location: '',
  });

  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };


  const [newData, setNewData] = useState(data);

  const [openCountry, setOpenCountry] = useState(false);
  const [openCity, setOpenCity] = useState(false);
  const [openDueDate, setOpenDueDate] = useState(false);
  const [openRate, setOpenRate] = useState(false);

/*
 * The function `closeAllFilters` closes all filter options by setting their corresponding state variables to false.
 */
  const closeAllFilters = () => {
    setOpenCountry(false);
    setOpenCity(false);
    setOpenDueDate(false);
    setOpenRate(false);
    setOpenCreatedAt(false);
    setOpenUpdatedAt(false);
  };

  const fetchCountryOptions = () => {
    closeAllFilters();

    setOpenCountry(true);
    let the_options = [];

    if (filtersTrigger.countries.initOptions.length < 1) {
      const fetchData = Country.getAllCountries();
      if (fetchData) {
        the_options = fetchData.map((el) => {
          return {
            key: el?.isoCode?.toLowerCase(),
            value: el?.isoCode,
            text: el.name,
            flag: el?.isoCode?.toLowerCase(),
          };
        });

        setFiltersTrigger({
          ...filtersTrigger,
          countries: {
            ...filtersTrigger.countries,
            initOptions: the_options,
            options: the_options,
          },
        });
      } else {
        setFiltersTrigger({
          ...filtersTrigger,
          countries: {
            ...filtersTrigger.countries,
          },
        });
      }
    }
  };

  const fetchCitiesOptions = () => {
    closeAllFilters();

    setOpenCity(true);
    let the_options = [];
    // console.log(filterOptions);
    if (filterOptions.countries) {
      const fetchData = State.getStatesOfCountry(filterOptions.countries);
      if (fetchData) {
        the_options = fetchData.map((el) => {
          return {
            key: el.isoCode,
            value: el.isoCode,
            text: el.name,
          };
        });

        setFiltersTrigger({
          ...filtersTrigger,
          cities: {
            ...filtersTrigger.cities,
            initOptions: the_options,
            options: the_options,
          },
        });
      } else {
        setFiltersTrigger({
          ...filtersTrigger,
          cities: {
            ...filtersTrigger.cities,
          },
        });
      }
    }
  };

  const handleSearchCountryChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      country: e.target.value,
    });
  };

  const handleSearchCityChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      city: e.target.value,
    });
  };

  // useEffect(() => {
  //   const re = new RegExp(_.escapeRegExp(filterSearch.country), 'i');
  //   const isMatch = (result) => re.test(result.text);
  //   setFiltersTrigger({
  //     ...filtersTrigger,
  //     countries: {
  //       ...filtersTrigger.countries,
  //       options:
  //         filterSearch.country.length > 0
  //           ? _.filter(filtersTrigger.countries.initOptions, isMatch)
  //           : filtersTrigger.countries.initOptions,
  //     },
  //   });
  //   document.body.addEventListener('click', closeAllFilters);

  //   // handleRequestedByTypeChange(filtersTrigger.requestedByType);
  // }, [filterSearch.country]);

  useEffect(() => {
  
    (async function() {
      let the_options = [];
      let the_options_location = [];

      const fetchDataLocation = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      );


      let parentOptions = [];
      let subOptions = [];

      if (fetchDataLocation?.response?.length > 0) {
        fetchDataLocation.response.forEach((el) => {
          if (!(role === "super_admin" || role=== "admin")) {
            // Check if the element's ID is included in allIds
            if (allIds?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => allIds?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  text: el.attributes.name,
                  image: { src: location_icon },
                  className: "main-location-dropdown-option",
                  disabled: true, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (allIds?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }
          } else {
            // Add parent option
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              className: "main-location-dropdown-option",
              image: { src: location_icon },
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                subOptions.push({
                  key: subEl.id,
                  value: subEl.id,
                  text: subEl.attributes.name,
                  image: { src: sub_location_icon },
                  className: "sub-location-dropdown-option",
                  parentId: el.id, // Keep track of the parent
                });
              });
            }
          }
        });

        // Sort sub-options alphabetically
        subOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted sub-options
        parentOptions.forEach((parentOption) => {
          the_options_location.push(parentOption);
          // Add sorted sub-options belonging to this parent
          subOptions.forEach((subOption) => {
            if (subOption.parentId === parentOption.key) {
              the_options_location.push(subOption);
            }
          });
        });

        // Update the options state
        // setOptions((prev) => ({
        //   ...prev,
        //   locations: the_options,
        // }));

        // return the_options;
      }

      // const fetchJobTitleData = await getAllJobTitles(organisationId);

      // if (fetchJobTitleData) {
      //   fetchJobTitleData.map((el) => {
      //     the_options_jobTitle.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.title,
      //     });
      //   });
      // }

      const fetchData = Country.getAllCountries();
      if (fetchData) {
        fetchData.forEach((el) => {
          the_options.push({
            key: el?.isoCode?.toLowerCase(),
            value: el?.isoCode,
            text: el.name,
            flag: el?.isoCode?.toLowerCase(),
          });
        });
      }

      setFiltersTrigger((prevState) => {
        return {
          ...prevState,
          countries: {
            ...filtersTrigger.countries,
            initOptions: the_options,
            options: the_options,
          },
          locations: {
            ...filtersTrigger.locations,
            initOptions: the_options_location,
            options: the_options_location,
          },
        };
      });

      // setFiltersTrigger({
      //   ...filtersTrigger,

      //   countries: {
      //     ...filtersTrigger.countries,
      //     initOptions: the_options,
      //     options: the_options,
      //   },
      // });
    })();
  }, []);

  useEffect(() => {
    (async function() {
      let the_options = [];
      if (filterOptions.countries) {
        const fetchData = await State.getStatesOfCountry(
          filterOptions.countries[0]
        );

        if (fetchData) {
          fetchData.forEach((el) => {
            the_options.push({
              key: `${el.isoCode}`,
              value: `${el.isoCode}`,
              text: el.name,
            });
          });
        }

        // console.log('THE COUNTRY SELECTED', the_options);

        setFiltersTrigger((prevState) => {
          return {
            ...prevState,
            cities: {
              initOptions: the_options,
              options: the_options,
            },
          };
        });
      } else {
        setFiltersTrigger((prevState) => {
          return {
            ...prevState,
            cities: {
              ...filtersTrigger.cities,
              initOptions: [],
              options: [],
            },
          };
        });
      }
    })();
  }, [filterOptions.countries]);

  let _newData = newData;
  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      var notInArray = ['ratePopup'];
      if (!notInArray.includes(e.target.id)) closeAllFilters();
    });

    (async function() {
      setloader(true);

      await dispatch(
        fetchCustomerWithFilter({
          organisationId,
          searchName: debounceValue,
          page: '1',
          pageSize: '25',
          location: filterOptions.locations,
          country: filterOptions.countries,
          city: filterOptions.cities,
          rate: filterOptions.rate,
          sort: sortData,
          role,
          allIds
          // location: filterOptions.locations,
        })
      );

      setloader(false);
    })();

    // if (filterOptions.locations.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.locations.includes(
    //       `${el.attributes?.location?.data?.id}`
    //     );
    //   });

    // if (filterOptions.countries.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return (
    //       el?.attributes?.billingInformation?.country &&
    //       filterOptions.countries.includes(
    //         el?.attributes?.billingInformation?.country
    //       )
    //     );
    //   });

    // if (filterOptions.rate.from || filterOptions.rate.to)
    //   _newData = _newData.filter((el) => {
    //     return (
    //       Number(el.attributes?.billingInformation?.rate) >=
    //         filterOptions.rate.from &&
    //       Number(el.attributes?.billingInformation?.rate) <=
    //         filterOptions.rate.to
    //     );
    //   });

    // if (filterOptions.cities.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return (
    //       el?.attributes?.billingInformation?.city &&
    //       filterOptions.cities.includes(
    //         el?.attributes?.billingInformation?.city
    //       )
    //     );
    //   });
    // if (filterOptions.createdAt.value > 0) {
    //   if (filterOptions.createdAt.value == 1) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.createdAt, 'd');
    //     });
    //     //console.log('today');
    //   }

    //   if (filterOptions.createdAt.value == 2) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.createdAt, 'month');
    //     });
    //     //console.log('This Month');
    //   }

    //   if (filterOptions.createdAt.value == 3) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.createdAt, 'year');
    //     });
    //     //console.log('This Year');
    //   }

    //   if (filterOptions.createdAt.value == 4) {
    //     _newData = _newData.filter((el) => {
    //       var toDate = moment().format('YYYY-MM-DD');
    //       var fromDate = moment()
    //         .subtract(7, 'days')
    //         .format('YYYY-MM-DD');

    //       return (
    //         el.attributes.createdAt >= fromDate &&
    //         el.attributes.createdAt <= toDate
    //       );
    //     });
    //     //console.log('last 7 days');
    //   }

    //   if (filterOptions.createdAt.value == 5) {
    //     _newData = _newData.filter((el) => {
    //       return moment()
    //         .subtract(1, 'months')
    //         .isSame(el.attributes.createdAt, 'month');
    //     });
    //     //console.log('Last month');
    //   }

    //   if (filterOptions.createdAt.value == 6) {
    //     _newData = _newData.filter((el) => {
    //       return moment()
    //         .subtract(1, 'years')
    //         .isSame(el.attributes.createdAt, 'year');
    //     });
    //     //console.log('Last year');
    //   }
    // }

    // if (filterOptions.updatedAt.value > 0) {
    //   if (filterOptions.updatedAt.value == 1) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.updatedAt, 'd');
    //     });
    //     //console.log('today');
    //   }

    //   if (filterOptions.updatedAt.value == 2) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.updatedAt, 'month');
    //     });
    //     //console.log('This Month');
    //   }

    //   if (filterOptions.updatedAt.value == 3) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.updatedAt, 'year');
    //     });
    //     //console.log('This Year');
    //   }

    //   if (filterOptions.updatedAt.value == 4) {
    //     _newData = _newData.filter((el) => {
    //       var toDate = moment().format('YYYY-MM-DD');
    //       var fromDate = moment()
    //         .subtract(7, 'days')
    //         .format('YYYY-MM-DD');

    //       return (
    //         el.attributes.updatedAt >= fromDate &&
    //         el.attributes.updatedAt <= toDate
    //       );
    //     });
    //     //console.log('last 7 days');
    //   }

    //   if (filterOptions.updatedAt.value == 5) {
    //     _newData = _newData.filter((el) => {
    //       return moment()
    //         .subtract(1, 'months')
    //         .isSame(el.attributes.updatedAt, 'month');
    //     });
    //     //console.log('Last month');
    //   }

    //   if (filterOptions.updatedAt.value == 6) {
    //     _newData = _newData.filter((el) => {
    //       return moment()
    //         .subtract(1, 'years')
    //         .isSame(el.attributes.updatedAt, 'year');
    //     });
    //     //console.log('Last year');
    //   }
    // }

    // if (searchTitle.length > 0) {
    //   const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
    //   const isMatch = (result) => re.test(result.attributes.name);

    //   _newData =
    //     searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    // }

    // if (_newData.length > 0) {
    //   setDataByFilter(_newData);
    // } else {
    //   setDataByFilter([]);
    // }
  }, [
    filterOptions.countries,
    filterOptions.locations,
    filterOptions.cities,
    debounceRate,
    debounceValue,
  ]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), 'i');
   
    const isMatch = (result) => {
      let ss = re.test(result.text);
     
    };

   
    let data = filtersTrigger.locations.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        ...filtersTrigger.locations,
        options:
          filterSearch.location.length > 0
            ? data
            : filtersTrigger.locations.initOptions,
      },
    });
  }, [filterSearch.location]);

  const headers = [
    { label: t.assets.table.ID, key: 'id' },
    { label: t.customers.table.name, key: 'name' },
    { label: t.customers.contact_name, key: 'contact_name' },
    { label: t.customers.form.phone, key: 'phone' },
    { label: t.customers.table.email, key: 'email' },
    { label: t.workOrders.table.location, key: 'location' },
    { label: t.locations.table.country, key: 'country' },
    { label: t.locations.table.city, key: 'city' },
   
    { label: t.customers.table.service_type, key: 'service_type' },
    { label: t.customers.table.hourly_rate, key: 'rate' },
    
  ];


/*
 * The `downloadXLS` function fetches customer data, processes it, and then downloads the data as an Excel file.
 */
  const downloadXLS = async () => {
    const rateHasValues = Object.values(filterOptions.rate).some(
      (value) => value !== null && value !== undefined  && value !== ''
    );

    // Check if any array in filterOptions has length greater than 0
    const arraysNotEmpty = Object.values(filterOptions).some(
      (option) => Array.isArray(option) && option.length > 0
    );
    // const hasValues = Object.values(filterOptions).some(option => {
    //   if (typeof option === 'object') {
    //     // For nested objects, check if any of their properties have a value

    //     return Object.values(option).some(subOption => subOption !== null && subOption !== undefined);
    //   } else {

    //     return (option) => option.length > 0
    //   }
    // })

    if ((!rateHasValues && !arraysNotEmpty) && searchTitle == '') {
      let mydata = await dispatch(fetchAllCustomerData({organisationId,role,allIds}));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }

    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let customers_data = [];

      customers_data.id = fileData[i].attributes?.countId;
      customers_data.name = fileData[i].attributes.name || t.common.na;

      customers_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description.replace(/\s+/g, ' ').trim()
        : t.common.na;

      customers_data.email = fileData[i]?.attributes?.email
        ? fileData[i].attributes?.email
        : t.common.na;

      customers_data.phone = fileData[i]?.attributes?.phone
        ? `+${fileData[i]?.attributes?.phone}`
        : t.common.na;

      customers_data.locations = fileData[i]?.attributes?.location?.data?.id
        ? fileData[i]?.attributes?.location?.data?.attributes?.name
        : t.common.na;

      customers_data.website = fileData[i]?.attributes?.website
        ? fileData[i].attributes?.website
        : t.common.na;

      customers_data.service_type = fileData[i]?.attributes?.serviceType
        ? fileData[i].attributes?.serviceType
        : t.common.na;

      customers_data.industry = fileData[i]?.attributes?.Industry
        ? fileData[i].attributes?.Industry
        : t.common.na;

      customers_data.contact_name = fileData[i]?.attributes?.contactInformation
        ?.name
        ? fileData[i]?.attributes?.contactInformation?.name
        : t.common.na;

      customers_data.contact_email = fileData[i]?.attributes?.contactInformation
        ?.email
        ? fileData[i]?.attributes?.contactInformation?.email
        : t.common.na;

      customers_data.contact_phone = fileData[i]?.attributes?.contactInformation
        ?.phone
        ? `+${fileData[i]?.attributes?.contactInformation?.phone}`
        : t.common.na;

      customers_data.billing_name = fileData[i]?.attributes?.billingInformation
        ?.name
        ? fileData[i]?.attributes?.billingInformation?.name
        : t.common.na;

      customers_data.country = fileData[i]?.attributes?.billingInformation
        ?.country
        ? Country.getCountryByCode(
            fileData[i]?.attributes?.billingInformation?.country
          )?.name
        : t.common.na;

      customers_data.city = fileData[i]?.attributes?.billingInformation?.city
        ? State.getStateByCodeAndCountry(
            fileData[i]?.attributes?.billingInformation?.city,
            fileData[i]?.attributes?.billingInformation?.country
          )?.name
        : t.common.na;

      customers_data.postal_code = fileData[i]?.attributes?.billingInformation
        ?.postalCode
        ? fileData[i]?.attributes?.billingInformation?.postalCode
        : t.common.na;

      customers_data.address = fileData[i]?.attributes?.billingInformation
        ?.address
        ? fileData[i]?.attributes?.billingInformation?.address
        : t.common.na;

      customers_data.rate = fileData[i]?.attributes?.billingInformation?.rate
        ? fileData[i]?.attributes?.billingInformation?.rate +
          ' ' +
          (fileData[i]?.attributes?.billingInformation?.currency
            ? fileData[i]?.attributes?.billingInformation?.currency
            : '')
        : t.common.na;

      customers_data.currency = fileData[i]?.attributes?.billingInformation
        ?.currency
        ? fileData[i]?.attributes?.billingInformation?.currency
        : t.common.na;

      customers_data.createdBy = fileData[i]?.attributes?.createdByUser?.data
        ? fileData[i]?.attributes?.createdByUser.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        customers_data.id,
        customers_data.name,
        customers_data.contact_name,
        customers_data.phone,
        // customers_data.description,
        customers_data.email,
        customers_data.locations,
        customers_data.country,
        customers_data.city,
        customers_data.service_type,
        customers_data.rate,

        //  customers_data.website,

        //  customers_data.industry,

        //  customers_data.contact_email,
        //  customers_data.contact_phone,

        //  customers_data.billing_name,

        //  customers_data.postal_code ,

        //  customers_data.address,

        //  customers_data.currency,
        //  customers_data.createdBy
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 15 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 10 }, // Created By column
      { wch: 10 }, // Created At column
      { wch: 25 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.customers.customers
    );
  };

/*
 * The `generatePDF` function processes data from `fileData` to generate a PDF file with specific customer information.
 */
  const generatePDF = () => {
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let customers_data = [];

      customers_data.id = fileData[i].attributes?.countId;
      customers_data.name = fileData[i].attributes.name || t.common.na;

      customers_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description.replace(/\s+/g, ' ').trim()
        : t.common.na;

      customers_data.email = fileData[i]?.attributes?.email
        ? fileData[i].attributes?.email
        : t.common.na;

      customers_data.phone = fileData[i]?.attributes?.phone
        ? `+${fileData[i]?.attributes?.phone}`
        : t.common.na;

      customers_data.locations = fileData[i]?.attributes?.location?.data?.id
        ? fileData[i]?.attributes?.location?.data?.attributes?.name
        : t.common.na;

      customers_data.website = fileData[i]?.attributes?.website
        ? fileData[i].attributes?.website
        : t.common.na;

      customers_data.service_type = fileData[i]?.attributes?.serviceType
        ? fileData[i].attributes?.serviceType
        : t.common.na;

      customers_data.industry = fileData[i]?.attributes?.Industry
        ? fileData[i].attributes?.Industry
        : t.common.na;

      customers_data.contact_name = fileData[i]?.attributes?.contactInformation
        ?.name
        ? fileData[i]?.attributes?.contactInformation?.name
        : t.common.na;

      customers_data.contact_email = fileData[i]?.attributes?.contactInformation
        ?.email
        ? fileData[i]?.attributes?.contactInformation?.email
        : t.common.na;

      customers_data.contact_phone = fileData[i]?.attributes?.contactInformation
        ?.phone
        ? `+${fileData[i]?.attributes?.contactInformation?.phone}`
        : t.common.na;

      customers_data.billing_name = fileData[i]?.attributes?.billingInformation
        ?.name
        ? fileData[i]?.attributes?.billingInformation?.name
        : t.common.na;

      customers_data.country = fileData[i]?.attributes?.billingInformation
        ?.country
        ? Country.getCountryByCode(
            fileData[i]?.attributes?.billingInformation?.country
          )?.name
        : t.common.na;

      customers_data.city = fileData[i]?.attributes?.billingInformation?.city
        ? State.getStateByCodeAndCountry(
            fileData[i]?.attributes?.billingInformation?.city,
            fileData[i]?.attributes?.billingInformation?.country
          )?.name
        : t.common.na;

      customers_data.postal_code = fileData[i]?.attributes?.billingInformation
        ?.postalCode
        ? fileData[i]?.attributes?.billingInformation?.postalCode
        : t.common.na;

      customers_data.address = fileData[i]?.attributes?.billingInformation
        ?.address
        ? fileData[i]?.attributes?.billingInformation?.address
        : t.common.na;

      customers_data.rate = fileData[i]?.attributes?.billingInformation?.rate
        ? fileData[i]?.attributes?.billingInformation?.rate +
          ' ' +
          (fileData[i]?.attributes?.billingInformation?.currency
            ? fileData[i]?.attributes?.billingInformation?.currency
            : '')
        : t.common.na;

      customers_data.currency = fileData[i]?.attributes?.billingInformation
        ?.currency
        ? fileData[i]?.attributes?.billingInformation?.currency
        : t.common.na;

      customers_data.createdBy = fileData[i]?.attributes?.createdByUser?.data
        ? fileData[i]?.attributes?.createdByUser.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        customers_data.id,
        customers_data.name,
        customers_data.contact_name,
        customers_data.phone,
        // customers_data.description,
        customers_data.email,
        customers_data.locations,
        customers_data.country,
        customers_data.city,
        customers_data.service_type,
        customers_data.rate,

        //  customers_data.website,

        //  customers_data.industry,

        //  customers_data.contact_email,
        //  customers_data.contact_phone,

        //  customers_data.billing_name,

        //  customers_data.postal_code ,

        //  customers_data.address,

        //  customers_data.currency,
        //  customers_data.createdBy
      ]);
    }
    const columnWidths = [, 30, 30];

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(
      headers,
      csvData,
      t.customers.customers,
      columnWidths,
      'A3'
    );
  };

  return (
    <>
      <div className="filters">
        <div className="text-right mb-3 pb-5 d-flex justify-content-between align-items-center views">
          <div className="list-view-option">
            <p className="single-view">
              <NavLink to="/customers">{t.customers.customer}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/vendors">{t.customers.vendor}</NavLink>
            </p>
          </div>
          {data?.length > 0 ? (
            <div className="table-searchbar" style={{ width: '240px' }}>
              <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
                icon={{ name: 'search' }}
                placeholder={t.locations.filter.search}
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </div>
          ) : null}
        </div>
      </div>
      {data?.length > 0 ? (
        <div className="filters-wrapper justify-content-between">
          {/* <div>
        <Input
          icon={{ name: 'search' }}
          placeholder={t.customers.filter.search}
          value={searchTitle}
          onChange={(e) => setSearchTitle(e.target.value)}
        />
      </div> */}

          <div className="flexbox table-right-dropdown">
          <LocationSearchDropdown
            title={t.workOrders.table.location}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            searchValue={filterSearch.location}
            handleSearchValue={handleSearchLocationChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.locations, value.value);
              setFilterOptions({
                ...filterOptions,
                locations: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                location: "",
              });
            }}
          />
            {/* countries */}
            {/* <Dropdown
          text={
            filterOptions.countries
              ? Country.getCountryByCode(filterOptions.countries).name
              : t.customers.filter.country
          }
          icon="globe"
          floating
          labeled
          button
          basic
          className="icon"
          style={{ marginRight: '10px' }}
          onOpen={() => fetchCountryOptions()}
          open={openCountry}
        >
          <Dropdown.Menu>
            <Input
              autoComplete="new-password"
              icon="search"
              iconPosition="left"
              className="search"
              value={filterSearch.country}
              onChange={handleSearchCountryChange}
            />
            <Dropdown.Divider />
            <Dropdown.Header content={t.customers.filter.filter_by_countries} />
            <Dropdown.Menu scrolling>
              {filtersTrigger.countries.options.map((option) => (
                <Dropdown.Item
                  key={option.key}
                  {...option}
                  selected={filterOptions.countries == option.value}
                  onClick={() => {
                    setOpenCountry(false);
                    setFilterOptions({
                      ...filterOptions,
                      countries: '',
                    });
                    setFilterOptions({
                      ...filterOptions,
                      countries:
                        filterOptions.countries == option.value
                          ? ''
                          : option.value,
                    });

                    setFilterSearch({
                      ...filterSearch,
                      country: '',
                    });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown> */}

            <CategoryDropdown
              title={t.locations.table.country}
              options={filtersTrigger.countries.options}
              selectedValues={filterOptions.countries}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions((prevState) => {
                    return {
                      ...prevState,
                      countries: [e.target.value],
                    };
                  });
                } else {
                  let filteredStatus = filterOptions.countries.filter(
                    (el) => el !== e.target.value
                  );
                  setFilterOptions({
                    ...filterOptions,
                    countries: filteredStatus,
                  });
                }
              }}
              icon={false}
              image={false}
            />
            <CategoryDropdown
              title={t.locations.table.city}
              options={filtersTrigger.cities.options}
              selectedValues={filterOptions.cities}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions((prevState) => {
                    return {
                      ...prevState,
                      cities: [e.target.value],
                    };
                  });
                } else {
                  let filteredStatus = filterOptions.cities.filter(
                    (el) => el !== e.target.value
                  );
                  setFilterOptions({
                    ...filterOptions,
                    cities: filteredStatus,
                  });
                }
              }}
              icon={false}
              image={false}
            />

            <Popup
              key={1}
              on="click"
              pinned
              open={openRate}
              position="bottom left"
              id="ratePopup"
              onClick={(e) => e.stopPropagation()}
              trigger={
                <div
                  basic
                  onClick={() => setOpenRate(!openRate)}
                  className={`popup-filter m-0 Category-dropdown popup  ${
                    filterOptions.rate.from || filterOptions.rate.to
                      ? 'blue-border'
                      : ''
                  } ${openRate ? 'active' : ''}`}
                >
                  {t.vendors.table.hourly_rate}
                  <DownChevron />
                  {/* {selectedLanguage === 'ar' ? (
                  <Icon name="dollar sign" color="#878c90" />
                ) : null} */}
                </div>
              }
              className={`${
                selectedLanguage == 'ar' ? 'asset-filter-rtl' : ''
              }`}
            >
              <div
                className="flexbox"
                style={{ minWidth: '200px' }}
                id="ratePopup"
              >
                <Form.Field id="ratePopup" style={{ width: '100%' }}>
                  <label className="label-item" id="ratePopup">
                    {t.people.filter.from}
                  </label>
                  <Input
                    id="ratePopup"
                    autoComplete="new-password"
                    fluid
                    type="number"
                    placeholder={t.people.filter.from}
                    value={filterOptions.rate.from}
                    onChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        rate: {
                          ...filterOptions.rate,
                          from: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Field>
                <Form.Field
                  id="ratePopup"
                  style={{ width: '100%', marginLeft: 2 }}
                >
                  <label id="ratePopup" className="label-item">
                    {t.people.filter.to}
                  </label>
                  <Input
                    id="ratePopup"
                    autoComplete="new-password"
                    fluid
                    type="number"
                    placeholder={t.people.filter.to}
                    value={filterOptions.rate.to}
                    onChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        rate: {
                          ...filterOptions.rate,
                          to: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Field>
              </div>
              <div className="form-button-container mt-0">
                <button
                  id="ratePopup"
                  className="form-action-button blue"
                  onClick={(e) => {
                    setFilterOptions({
                      ...filterOptions,
                      rate: {
                        from: "",
                        to: "",
                      },
                    });

                    // console.log(filterOptions);
                  }}
                >
                  {t.common.reset}
                </button>
              </div>
            </Popup>

            {/* cities */}
            {/* <Dropdown
            text={
              filterOptions.cities.length > 0
                ? filterOptions.cities.length +
                  ` ${t.customers.filter.selected}`
                : t.customers.filter.cities
            }
            icon="building outline"
            floating
            labeled
            button
            basic
            multiple
            className="icon"
            style={{ marginRight: '10px' }}
            onOpen={() => fetchCitiesOptions()}
            open={openCity}
          >
            <Dropdown.Menu>
              <Input
                autoComplete="new-password"
                icon="search"
                iconPosition="left"
                className="search"
                value={filterSearch.city}
                onChange={handleSearchCityChange}
              />
              <Dropdown.Divider />
              <Dropdown.Header content={t.customers.filter.search_by_cities} />
              <Dropdown.Menu scrolling>
                {filtersTrigger.cities.options.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    {...option}
                    selected={filterOptions.cities.includes(option.value)}
                    onClick={() => {
                      const newArray = toggle(
                        filterOptions.cities,
                        option.value
                      );
                      setFilterOptions({
                        ...filterOptions,
                        cities: newArray,
                      });

                      setFilterSearch({
                        ...filterSearch,
                        city: '',
                      });
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown> */}

            {/* due date
        <Dropdown
          icon="calendar alternate outline"
          style={{ marginRight: '10px' }}
          floating
          button
          labeled
          basic
          options={filtersTrigger.dueDate.options}
          className="icon"
          text={filterOptions.dueDate.text}
          open={openDueDate}
          onOpen={() => {
            closeAllFilters();

            setOpenDueDate(true);
            setFiltersTrigger({
              ...filtersTrigger,
              dueDate: {
                ...filtersTrigger.dueDate,
                open: true,
              },
            });
          }}
          onChange={(e, { value, text }) => {
            // console.log(value);
            setFilterOptions({
              ...filterOptions,
              dueDate: {
                ...filterOptions.dueDate,
                value,
                text: value.text,
              },
            });
          }}
        /> */}

            {/* createdAt */}
            {/* <Dropdown
            icon="calendar alternate outline"
            style={{ marginRight: '10px' }}
            floating
            button
            labeled
            basic
            options={filtersTrigger.createdAt.options}
            className="icon"
            text={filterOptions.createdAt.text}
            open={openCreatedAt}
            onOpen={() => {
              closeAllFilters();

              setOpenCreatedAt(true);
              setFiltersTrigger({
                ...filtersTrigger,
                createdAt: {
                  ...filtersTrigger.createdAt,
                  open: true,
                },
              });
            }}
            onChange={(e, { value, text }) => {
              // console.log(value);
              setFilterOptions({
                ...filterOptions,
                createdAt: {
                  ...filterOptions.createdAt,
                  value,
                  text: value.text,
                },
              });
            }}
          /> */}

            {/* updatedAt */}
            {/* <Dropdown
            icon="calendar alternate outline"
            style={{ marginRight: '10px' }}
            floating
            button
            labeled
            basic
            options={filtersTrigger.updatedAt.options}
            className="icon"
            text={filterOptions.updatedAt.text}
            open={openUpdatedAt}
            onOpen={() => {
              closeAllFilters();

              setOpenUpdatedAt(true);
              setFiltersTrigger({
                ...filtersTrigger,
                updatedAt: {
                  ...filtersTrigger.updatedAt,
                  open: true,
                },
              });
            }}
            onChange={(e, { value, text }) => {
              // console.log(value);
              setFilterOptions({
                ...filterOptions,
                updatedAt: {
                  ...filterOptions.updatedAt,
                  value,
                  text: value.text,
                },
              });
            }}
          /> */}
          </div>

          <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ">
            <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle
              style={{ position: "relative", cursor: "pointer" }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              color="primary"
              className="option-toggle"
            >
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "ar" ? "top right" : "top left"}
                show={showTooltip}
                textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                fontSize="12px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="4px 4px 4px 8px"
                borderRadius="5px"
                static
                moveRight="10px"
                moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
              >
                <span className="work-order-tooltip">
                  {t.common.page_action}
                </span>
              </ToolTip>
              <ActionBtnIcon />{" "}
            </DropdownToggle>
              <DropdownMenu className={selectedLanguage == 'ar' ? 'rtl' : ''}>
                {/* {role !== 'view_only' ? (
                  <>
                    <DropdownItem>
                      <ShareIcon />
                      {t.common.share}
                    </DropdownItem>
                    <DropdownItem className="divider" />
                  </>
                ) : null} */}
                <DropdownItem className="divider" />
                <DropdownItem onClick={() => downloadXLS()}>
                  <DownloadIcon /> {t.common.download_sheet}
                </DropdownItem>
                <DropdownItem className="divider" />
                {/* <DropdownItem onClick={() => generatePDF()}>
                  <PdfIcon /> {t.common.download_pdf}
                </DropdownItem>
                <DropdownItem className="divider" /> */}
                {/* <DropdownItem>
                  <UploadIcon />
                  {t.common.upload}
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Filters;
